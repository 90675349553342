import cn from "classnames";

import "./styles.scss";

type Props = {
  typing: boolean;
  img?: string;
  stage?: string;
  partialContent?: string;
};

function Loader({ typing, img, stage, partialContent }: Props) {

  return (
    <div className={cn("loader", { active: typing })}>
      {img && (<img src={img} className="rcw-avatar" alt="profile" />)}
      <div className="loader-outer-container">
        {!partialContent ? (
          <div className="loader-container">
            <span className="loader-dots"></span>
            <span className="loader-dots"></span>
            <span className="loader-dots"></span>
          </div>
        ) : (
          <div className="loader-partial-content-container">
            {partialContent}
          </div>
        )}
        {stage && (
          <div className="loader-stage">
            {stage}
          </div>
        )}
      </div>
    </div>
  );
}

export default Loader;
