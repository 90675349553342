import { ElementType } from 'react';

import { ComposerComponent, ImageState, InteractiveButton, InternalMediaInteractiveButtonWrapper, LinkParams, MessageTypes, Metadata } from '../types';
import * as actionsTypes from './types';

export function toggleChat(): actionsTypes.ToggleChat {
  return {
    type: actionsTypes.TOGGLE_CHAT
  };
}

export function toggleContactForm(): actionsTypes.ToggleContactForm{
  return{
    type: actionsTypes.TOGGLE_CONTACT_FORM
  };
}

export function toggleInputDisabled(): actionsTypes.ToggleInputDisabled {
  return {
    type: actionsTypes.TOGGLE_INPUT_DISABLED
  };
}

export function addUserMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number): actionsTypes.AddUserMessage {
  return {
    type: actionsTypes.ADD_NEW_USER_MESSAGE,
    text,
    props,
    customTimeStamp,
    id,
    index
  };
}

export function addResponseMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number, quickButtons?: InteractiveButton[], internalMediaButton?: InternalMediaInteractiveButtonWrapper, metadata?: Metadata): actionsTypes.AddResponseMessage {
  return {
    type: actionsTypes.ADD_NEW_RESPONSE_MESSAGE,
    text,
    props,
    customTimeStamp,
    id,
    index,
    quickButtons,
    internalMediaButton,
    metadata,
  };
}

export function addPreviousUserMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number): actionsTypes.AddPreviousUserMessage {
  return {
    type: actionsTypes.ADD_PREVIOUS_USER_MESSAGE,
    text,
    props,
    customTimeStamp,
    id,
    index
  };
}

export function addPreviousResponseMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number, quickButtons?: InteractiveButton[], internalMediaButton?: InternalMediaInteractiveButtonWrapper, rating?: MessageTypes['rating'], metadata?: Metadata): actionsTypes.AddPreviousResponseMessage {
  return {
    type: actionsTypes.ADD_PREVIOUS_RESPONSE_MESSAGE,
    text,
    props,
    customTimeStamp,
    id,
    index,
    quickButtons,
    internalMediaButton,
    rating,
    metadata,
  };
}

export function toggleMsgLoader(): actionsTypes.ToggleMsgLoader {
  return {
    type: actionsTypes.TOGGLE_MESSAGE_LOADER
  }
}

export function togglePreviousMsgLoader(): actionsTypes.TogglePreviousMsgLoader {
  return {
    type: actionsTypes.TOGGLE_PREVIOUS_MESSAGE_LOADER
  }
}

export function addLinkSnippet(link: LinkParams, id?: string): actionsTypes.AddLinkSnippet {
  return {
    type: actionsTypes.ADD_NEW_LINK_SNIPPET,
    link,
    id
  };
}

export function renderCustomComponent(
  component: ElementType,
  props?: any,
  showAvatar?: boolean,
  id?: string
): actionsTypes.RenderCustomComponent {
  return {
    type: actionsTypes.ADD_COMPONENT_MESSAGE,
    component,
    props,
    showAvatar,
    id
  };
}

export function renderPreviousCustomComponent(
  component: ElementType,
  props?: any,
  showAvatar?: boolean,
  id?: string
): actionsTypes.RenderPreviousCustomComponent {
  return {
    type: actionsTypes.ADD_PREVIOUS_COMPONENT_MESSAGE,
    component,
    props,
    showAvatar,
    id
  };
}

export function dropMessages(position?: number): actionsTypes.DropMessages {
  return {
    type: actionsTypes.DROP_MESSAGES,
    position
  };
}

export function dropMessageButtons(id: string): actionsTypes.DropMessageButtons {
  return {
    type: actionsTypes.DROP_MESSAGE_BUTTONS,
    id
  };
}

export function hideAvatar(index: number): actionsTypes.HideAvatar {
  return {
    type: actionsTypes.HIDE_AVATAR,
    index
  };
}

export function setQuickButtons(buttons: Array<{ label: string, value: string | number }>): actionsTypes.SetQuickButtons {
  return {
    type: actionsTypes.SET_QUICK_BUTTONS,
    buttons
  }
}

export function deleteMessages(count: number, id?: string): actionsTypes.DeleteMessages {
  return {
    type: actionsTypes.DELETE_MESSAGES,
    count,
    id
  }
}

export function setBadgeCount(count: number): actionsTypes.SetBadgeCount {
  return {
    type: actionsTypes.SET_BADGE_COUNT,
    count
  }
}

export function markAllMessagesRead(): actionsTypes.MarkAllMessagesRead {
  return {
    type: actionsTypes.MARK_ALL_READ
  }
}

export function openFullscreenPreview(payload: ImageState): actionsTypes.FullscreenPreviewActions {
  return {
    type: actionsTypes.OPEN_FULLSCREEN_PREVIEW,
    payload
  };
}

export function closeFullscreenPreview(): actionsTypes.FullscreenPreviewActions {
  return {
    type: actionsTypes.CLOSE_FULLSCREEN_PREVIEW
  };
}

export function setHeaderTitle(title: string): actionsTypes.SetHeaderTitle{
  return {
    type: actionsTypes.SET_HEADER_TITLE,
    title
  }
}

export function setHeaderLogo(logo: string): actionsTypes.SetHeaderLogo {
  return {
    type: actionsTypes.SET_HEADER_LOGO,
    logo
  };
}

export function setWidgetIcon(icon: string): actionsTypes.SetWidgetIcon {
  return {
    type: actionsTypes.SET_WIDGET_ICON,
    icon
  };
}

export function setAvatarImage(image: string): actionsTypes.SetAvatarImage {
  return {
    type: actionsTypes.SET_AVATAR_IMAGE,
    image
  }
}

export function setThemeColor(color: string): actionsTypes.SetThemeColor{
  return {
    type: actionsTypes.SET_THEME_COLOR,
    color
  }
}

export function setTextColor(color: string): actionsTypes.SetTextColor{
  return {
    type: actionsTypes.SET_TEXT_COLOR,
    color
  }
}

export function setPassword(pw: string): actionsTypes.SetPassword{
  return {
    type: actionsTypes.SET_PASSWORD,
    pw
  }
}

export function setStompUrl(url: string): actionsTypes.SetStompUrl{
  return{
    type: actionsTypes.SET_STOMP_URL,
    url
  }
}

export function setStompUser(stompUser: string): actionsTypes.SetStompUser{
  return{
    type: actionsTypes.SET_STOMP_USER,
    stompUser
  }
}

export function setParentUrl(url: string): actionsTypes.SetParentUrl{
  return{
    type: actionsTypes.SET_PARENT_URL,
    url
  }
}

export function setOptionFields(optionFields: Array<{ id: number, label: string, name: string, options: string[], required: boolean, type: string}>): actionsTypes.SetOptionFields{
  return {
    type: actionsTypes.SET_OPTION_FIELDS,
    optionFields
  }
}

export function setDisableComposerComponents(components: ComposerComponent[]): actionsTypes.SetDisableComposerComponents {
  return {
    type: actionsTypes.SET_DISABLE_COMPOSER_COMPONENTS,
    components,
  };
}

export function addTypingIndicatorExpiry(responseIdentifier: string, expiry: number, stage?: string, partialContent?: string): actionsTypes.AddTypingIndicatorExpiry {
  return {
    type: actionsTypes.ADD_TYPING_INDICATOR_EXPIRY,
    responseIdentifier,
    expiry,
    stage,
    partialContent,
  }
}

export function removeTypingIndicator(responseIdentifier: string): actionsTypes.RemoveTypingIndicator {
  return {
    type: actionsTypes.REMOVE_TYPING_INDICATOR,
    responseIdentifier,
  }
}

export function setMessageRatingEnabled(enabled: boolean): actionsTypes.SetMessageRatingEnabled {
  return {
    type: actionsTypes.SET_MESSAGE_RATING_ENABLED,
    enabled,
  }
}

export function putMessageRatingStarted(messageId: string, rating: actionsTypes.PutMessageRatingStarted['rating']): actionsTypes.PutMessageRatingStarted {
  return {
    type: actionsTypes.PUT_MESSAGE_RATING_STARTED,
    messageId,
    rating,
  }
}

export function putMessageRatingSucceeded(messageId: string, rating: actionsTypes.PutMessageRatingSucceeded['rating']): actionsTypes.PutMessageRatingSucceeded {
  return {
    type: actionsTypes.PUT_MESSAGE_RATING_SUCCEEDED,
    messageId,
    rating,
  }
}

export function putMessageRatingFailed(messageId: string, errorMessage: string): actionsTypes.PutMessageRatingFailed {
  return {
    type: actionsTypes.PUT_MESSAGE_RATING_FAILED,
    messageId,
    errorMessage,
  }
}

export function setMessageRatingNegativeCommentPrompt(prompt: string): actionsTypes.SetMessageRatingNegativeCommentPrompt {
  return {
    type: actionsTypes.SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT,
    prompt,
  }
}
