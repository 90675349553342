import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const UseStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#fff',
    borderRadius: '8px',
  }
}))

const phoneInput = (props, ref) => {
  const classes = UseStyles()

  return (

    <TextField
      {...props}
      InputProps={{
        className: classes.input,
        style: {
          borderRadius: '8px'
        }
      }}
      inputRef={ref}
      fullWidth
      size='small'
      label='Phone Number'
      variant='outlined'
      name='phone'
    />
  )
}
export default forwardRef(phoneInput)