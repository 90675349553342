import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { Field, Formik, FormikProps } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import FadeIn from 'react-fade-in';
import { IoMdStar } from 'react-icons/io';
import Loader from 'react-loader-spinner';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import * as Stomp from 'stompjs';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import 'yup-phone';

import { CountrySelectWithIcon } from './CountrySelect';
import CustomPhoneNumber from './PhoneNumber';
import Widget from './components/Widget';
import {
  addPreviousResponseMessage,
  addPreviousUserMessage,
  addResponseMessage,
  addTypingIndicatorExpiry,
  addUserMessage,
  deleteMessages,
  dropMessages,
  removeTypingIndicator,
  renderCustomComponent,
  renderPreviousCustomComponent,
  setAvatarImage,
  setBadgeCount,
  setDisableComposerComponents,
  setHeaderLogo,
  setHeaderTitle,
  setMessageRatingEnabled,
  setMessageRatingNegativeCommentPrompt,
  setOptionFields,
  setParentUrl,
  setPassword,
  setQuickButtons,
  setStompUrl,
  setStompUser,
  setTextColor,
  setThemeColor,
  setWidgetIcon,
  toggleContactForm,
  toggleInputDisabled,
  togglePreviousMsgLoader,
  toggleWidget,
} from './store/dispatcher';
import {
  ComposerComponent,
  GlobalState,
  InteractiveButton,
  InteractiveButtonType,
  OptionFieldType,
} from './store/types';

import apiClient from './apiClient';

import disconnected from './assets/disconnected.jpg';
import farewell from './assets/farewell.jpg';

import '@fontsource/source-sans-pro';
import { withStyles } from '@material-ui/core/styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-phone-number-input/style.css';
import './App.css';
import './PostChatSurvey.css';
import './errorPrompt.css';
import './submittedSurvey.css';
import './welcomeForm.css';

type Client = any;

const regexExp = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const params = new URLSearchParams(window.location.search);
const isFullScreenMode = params.get('fullScreenMode') === '1' ? true : false;
// if a visitor-token is passed in, use that
apiClient.setVisitorToken(params.get('visitorToken') || '');

if (!apiClient.visitorToken) {
  if (localStorage.getItem('visitorToken') === null || !regexExp.test(localStorage.getItem('visitorToken')!)) {
    const vt = uuidv4();
    localStorage.setItem('visitorToken', vt);
  }

  apiClient.setVisitorToken(localStorage.visitorToken);
} else if (!regexExp.test(apiClient.visitorToken)) {
  throw new Error('Invalid visitor token passed in, expected uuid-v4 format');
}

const sender_identifier = uuidv4();

apiClient.setSessionId('');
apiClient.setWidgetToken(process.env.REACT_APP_WEB_WIDGET_TOKEN || params.get('widget') || '');
const memberId = params.get('memberID') || '';
if (memberId) {
  apiClient.setVisitorToken(memberId);
  apiClient.setIsMemberMode(true);
}
apiClient.setNonce(decodeURIComponent(params.get('memberNONCE') || ''));

let isNewDay = false;
let isEndingMessage = false;

if (params.get('parent') !== null) {
  if (isMobile) {
    window.parent.postMessage('isMobile', params.get('parent')!);
  }

  setParentUrl(params.get('parent')!);
}
if (params.get('disableComposer') !== null) {
  const components = `${params.get('disableComposer')}`
    .split(',')
    .map((s) => s.trim())
    .filter((s: any) => [ComposerComponent.Attachment, ComposerComponent.Emoji].includes(s)) as ComposerComponent[];
  if (components.length) {
    setDisableComposerComponents(components);
  }
}

if (isFullScreenMode) toggleWidget();

function App() {
  const isShowingForm = useSelector((state: GlobalState) => state.behavior.showContactForm);
  const headerTitle = useSelector((state: GlobalState) => state.responses.headerTitle);
  const headerLogo = useSelector((state: GlobalState) => state.responses.headerLogo);
  const widgetIcon = useSelector((state: GlobalState) => state.responses.widgetIcon);
  const avatarImage = useSelector((state: GlobalState) => state.responses.avatarImage);
  const password = useSelector((state: GlobalState) => state.responses.password);
  const stompUrl = useSelector((state: GlobalState) => state.responses.stompUrl);

  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);

  const [isContactForm, setIsContactForm] = useState(true);
  const [stompClient, setStompClient] = useState<Client>('');
  const [isValidWidget, setIsValidWidget] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endingMessage, setEndingMessage] = useState('');
  const [timeStampOffset, setTimeStampOffset] = useState(Date.now());
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [loadingPMCount, setLoadingPMCount] = useState(0);
  const [widgetConfig, setWidgetConfig] = useState({});

  var fi = document.getElementById('favicon');

  useEffect(() => {
    getWidget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setBadgeCount(3);
    toggleInputDisabled();

    if (isShowingForm) {
      if (isContactForm) {
        // console.log("first contact form");

        renderContactForm();
      } else {
        // console.log("show survey");
        renderCustomComponent(SurveySubmitted, { renderContactForm: renderContactForm });
        setIsContactForm(true);
      }
    } else {
      // console.log('Dropping messages...', isShowingForm);
      //toggleInputDisabled();
      //toggleMsgLoader();
      dropMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingForm]);

  useEffect(() => {
    if (!!stompUrl) {
      // console.log("starting stomp");

      var client = Stomp.client(`${stompUrl}/ws`);

      setStompClient(client);

      const connectCallback = () => {
        // console.log("connecting to ", sessionID);
        client.subscribe(`/topic/${apiClient.sessionId}`, onMessage);
        client.subscribe(`/topic/${apiClient.sessionId}-typing-indicator`, onTypingIndicator);
        // client.reconnectDelay = 300;
        dropMessages();

        // if(lastSessionID === "")
        //   addResponseMessage(welcomeMessage);
        // else getPreviousMessages();
        getPreviousMessages();

        setStompUrl('');
        toggleInputDisabled();
        // console.log("connected")
      };

      const errorCallback = (error) => {
        dropMessages();
        // renderCustomComponent(ConnectionErrorPrompt);
        toggleInputDisabled();
        setTimeStampOffset(Date.now());
        loadSession();
        console.log(error);
      };

      const onMessage = (msg) => {
        // console.log("isNewDay: ", isNewDay);

        // console.log(msg);

        try {
          const msgBody = JSON.parse(msg.body);
          // console.log(msgBody)

          if (isNewDay && !(msgBody.sender === 'client' && msgBody.sender_identifier === sender_identifier)) {
            // console.log("adding today");
            renderCustomComponent(DayStamp, { date: 'Today' });
            isNewDay = false;
          }

          if (msgBody.type === 'text') {
            if (
              msgBody.chat_id === apiClient.sessionId &&
              msgBody.sender === 'client' &&
              msgBody.sender_identifier !== sender_identifier
            ) {
              // console.log("adding client text")

              addUserMessage(msgBody.text, undefined, undefined, msgBody._id);
            } else if (msgBody.sender === 'agent') {
              addResponseMessage(msgBody.text, undefined, undefined, msgBody._id, undefined, undefined);
            }
          } else if (msgBody.type === 'image' || msgBody.type === 'document') {
            var imageFormat = ['JPEG', 'JPG', 'PNG', 'GIF', 'SVG'];
            var fileExtension = msgBody.media_url.split('.').pop();

            if (
              msgBody.chat_id === apiClient.sessionId &&
              msgBody.sender === 'client' &&
              msgBody.sender_identifier !== sender_identifier
            ) {
              // console.log("adding client file")

              addUserMessage(
                'non-text-object',
                () => {
                  return (
                    <div>
                      {imageFormat.includes(fileExtension.toUpperCase()) ? (
                        <CustomZoom user="client" media_url={msgBody.media_url} />
                      ) : (
                        <div className="client-document">
                          <IconButton
                            edge="start"
                            onClick={() => {
                              window.open(msgBody.media_url);
                            }}
                            style={{ fontSize: '12px' }}
                          >
                            <Description style={{ fontSize: 15 }} />
                            {msgBody.filename}
                          </IconButton>
                        </div>
                      )}
                    </div>
                  );
                },
                undefined,
                msgBody._id,
              );
            } else if (msgBody.sender === 'agent') {
              addResponseMessage(
                'non-text-object',
                () => {
                  return (
                    <div>
                      {imageFormat.includes(fileExtension.toUpperCase()) ? (
                        <CustomZoom user="response" media_url={msgBody.media_url} />
                      ) : (
                        <div className="response-document">
                          <IconButton
                            edge="start"
                            onClick={() => {
                              window.open(msgBody.media_url);
                            }}
                            style={{ fontSize: '12px' }}
                          >
                            <Description style={{ fontSize: 15 }} />
                            {msgBody.filename}
                          </IconButton>
                        </div>
                      )}
                    </div>
                  );
                },
                undefined,
                msgBody._id,
              );
            }
          } else if (msgBody.type === 'interactive') {
            if (msgBody.interactive.type === 'button') {
              const buttons: InteractiveButton[] = msgBody.interactive.action.buttons.map((button) => {
                return {
                  type: InteractiveButtonType.Button,
                  label: button.reply.title,
                  value: button.reply.id,
                  rawButton: button,
                };
              });

              addResponseMessage(msgBody.interactive.body.text, undefined, undefined, msgBody._id, undefined, buttons);
            } else if (msgBody.interactive.type === 'internalMedia') {
              const buttons: InteractiveButton[] = msgBody.interactive.action.buttons.map((internalMediaButton) => {
                return {
                  type: InteractiveButtonType.InternalMediaButton,
                  label: internalMediaButton.internalMedia.title,
                  value: internalMediaButton.internalMedia.key,
                  rawButton: internalMediaButton,
                };
              });
              addResponseMessage(
                msgBody.interactive.body.text,
                undefined,
                undefined,
                msgBody._id,
                undefined,
                undefined,
                {
                  prefix: msgBody.interactive.action.prefix ?? '',
                  buttons,
                },
              );
            }
          } else if (msgBody.type === 'reply') {
            if (
              msgBody.chat_id === apiClient.sessionId &&
              msgBody.sender === 'client' &&
              msgBody.sender_identifier !== sender_identifier
            ) {
              // console.log("adding client text")

              addUserMessage(msgBody.reply.button_reply.title, undefined, undefined, msgBody._id);
            } else if (msgBody.sender === 'agent') {
              addResponseMessage(
                msgBody.reply.button_reply.title,
                undefined,
                undefined,
                msgBody._id,
                undefined,
                undefined,
              );
            }
          }

          if (msgBody.responseIdentifier) {
            removeTypingIndicator(msgBody.responseIdentifier);
          }
        } catch (err) {
          console.error('Error in parsing incoming message data');
        }
      };

      const onTypingIndicator = (event) => {
        try {
          const body = JSON.parse(event.body);
          if (typeof body.expiry === 'number' && typeof body.responseIdentifier === 'string') {
            addTypingIndicatorExpiry(body.responseIdentifier, body.expiry, body.stage, body.partialContent);
          } else {
            throw new Error('Unrecognized format');
          }
        } catch (err) {
          console.error('Error in parsing incoming typing indicator data', (err as Error).message);
        }
      };

      if (client !== undefined) {
        const stompUser = apiClient.visitorToken;
        client.connect(stompUser, password, connectCallback, errorCallback);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stompUrl]);

  const renderContactForm = function () {
    if (apiClient.isMemberMode) {
      toggleContactForm();
      toggleInputDisabled();
    } else {
      dropMessages();
      renderCustomComponent(ContactForm);
    }
  };

  const getWidget = async function () {
    try {
      const response = await apiClient.fetchWidget();
      // console.log("widget_response: ", response);
      const data = response.data;
      console.log('response.data: ', data);
      const getTextColor = (hex) => {
        const rgbhex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        //console.log(rgbhex);
        if (rgbhex) {
          const brightness = Math.round(
            (parseInt(rgbhex[1], 16) * 299 + parseInt(rgbhex[2], 16) * 587 + parseInt(rgbhex[3], 16) * 114) / 1000,
          );

          //console.log(brightness);

          return brightness > 125 ? 'black' : 'white';
        }
      };

      const textColor = getTextColor(data.themeColorHex);

      // console.log(data.contactFields)

      const optionFields = [
        {
          id: -1,
          label: '',
          name: '',
          options: [],
          required: false,
          type: '',
        },
        {
          id: -1,
          label: '',
          name: '',
          options: [],
          required: false,
          type: '',
        },
        {
          id: -1,
          label: '',
          name: '',
          options: [],
          required: false,
          type: '',
        },
      ];

      for (let i = 0; i < data.contactFields.length; ++i) optionFields[i] = data.contactFields[i];

      setOptionFields(optionFields);
      setHeaderTitle(data.name);
      setHeaderLogo(data.logoUrl);
      setWidgetIcon(data.widgetLogoUrl);
      setThemeColor(data.themeColorHex);
      setTextColor(textColor!);
      setAvatarImage(data.avatarUrl ?? '');
      // setIsValidWidget(true)
      if (data.endingMessage !== '') {
        setEndingMessage(data.endingMessage);
      }

      if (data.logoConfig) {
        setWidgetConfig(data.logoConfig);
        window.parent.postMessage(
          { name: 'widgetConfig', width: data.logoConfig.width, height: data.logoConfig.height },
          params.get('parent')!,
        );
      }

      setMessageRatingEnabled(data.enableMessageRating ?? false);
      setMessageRatingNegativeCommentPrompt(data.messageRatingNegativeCommentPrompt ?? '');

      if (!apiClient.isMemberMode) {
        await apiClient.fetchVisitorNonce();
      }
      if (apiClient.nonce) {
        await apiClient.fetchAccessToken();
        await loadSession();
      }
      setIsValidWidget(true);
    } catch (err) {
      console.log(err);
    }
  };

  const postMessage = async function (text: string) {
    await apiClient.sendTextMessage(text, sender_identifier);
  };

  const postReplyMessage = async function (title: string, id: string, target_type: string, target_id: string) {
    await apiClient.sendReplyMessage(title, id, target_type, target_id, sender_identifier);
  };

  const postFile = async function (file: File, type: string, index: number) {
    const response = await apiClient.sendMediaMessage(file, type, sender_identifier);
    const message = response.data.message;
    const date = new Date(message.timestamp);
    if (message.type !== 'image') {
      deleteMessages(index);
      addUserMessage(
        'Replacement',
        () => {
          return (
            <div className="client-document">
              <IconButton
                edge="start"
                onClick={() => {
                  window.open(message.media_url);
                }}
                style={{ fontSize: '12px' }}
              >
                <Description style={{ fontSize: 15 }} />
                {message.filename}
              </IconButton>
            </div>
          );
        },
        format(date, 'HH:mm'),
        undefined,
        index,
      );
    }
    setIsUploadingFile(false);
  };

  const postEndSession = async function () {
    await apiClient.endSession();
  };

  const loadSession = async function () {
    try {
      const response = await apiClient.loadSession();
      const data = response.data;
      // console.log(data);
      if (isShowingForm) {
        toggleContactForm();
        toggleInputDisabled();
      }
      setStompUser(data.subscribe.visitor_token);
      setPassword(data.subscribe.password);
      setStompUrl(data.subscribe.url);
    } catch (err) {
      if ((err as AxiosError).response) {
        console.log((err as AxiosError).response);
      } else {
        dropMessages();
        renderCustomComponent(LoaderSpinner);
        return new Promise((resolve) => {
          setTimeout(resolve, 3000);
        }).then(loadSession);
      }
    }
  };

  const getPreviousMessages = async function () {
    // console.log("time stamp before getting messages: ", timeStampOffset)

    apiClient
      .fetchMessages(timeStampOffset)
      .then((response) => {
        const data = response.data;

        if (data.messages.length === 0) {
          return 0;
        }

        // console.log('LPM response: ', data.messages);
        // console.log(data.messages[0],data.messages.length-1);
        // console.log(data.messages[19],data.messages.length-1);

        togglePreviousMsgLoader();

        const currentDate = new Date();

        var lastDate = new Date(data.messages[0].timestamp);
        // const currentDateValue = parseInt(currentDate.getFullYear() + ('0'+ currentDate.getMonth()+1).slice(-2) + ('0'+ currentDate.getDate()).slice(-2) , 10);

        // var oldestDate = new Date(data.messages[data.messages.length-1].timestamp);

        var i = 0;

        data.messages.forEach((message) => {
          const tempDate = new Date(message.timestamp);

          // console.log("lastDate: ", lastDate)
          // console.log("tempDate: ", tempDate)
          // console.log("previousDate: ", format(new Date(timeStampOffset), "yyyy-MM-dd"))

          if (i === 0) {
            if (loadingPMCount === 0 && format(tempDate, 'yyyy-MM-dd') !== format(currentDate, 'yyyy-MM-dd')) {
              isNewDay = true;
            } else if (
              loadingPMCount > 0 &&
              format(tempDate, 'yyyy-MM-dd') === format(new Date(timeStampOffset), 'yyyy-MM-dd')
            ) {
              dropMessages(-1);
            }
          }

          if (i > 0 && format(tempDate, 'yyyy-MM-dd') !== format(lastDate, 'yyyy-MM-dd')) {
            const difference = Math.round((currentDate.getTime() - lastDate.getTime()) / (1000 * 3600 * 24));

            renderDayStamp(difference, format(lastDate, 'yyyy-MM-dd'));
          }

          if (message.type === 'text')
            message.sender === 'client'
              ? addPreviousUserMessage(message.text, undefined, format(tempDate, 'HH:mm'), message._id, i)
              : addPreviousResponseMessage(
                  message.text,
                  undefined,
                  format(tempDate, 'HH:mm'),
                  message._id,
                  i,
                  undefined,
                  undefined,
                  message.rating,
                  message.metadata,
                );
          else if (message.type === 'image' || message.type === 'document')
            // message.sender === "client"?addPreviousUserMessage(message.text,()=>{return <div>{message.type==="image"?<Zoom zoomMargin={100}><div className="client-document"><img alt="" src={message.media_url} className="response-document-image"/></div></Zoom>:<div className="client-document"><IconButton edge='start' onClick={()=> {window.open(message.media_url)}} style={{fontSize: "12px"}}><Description style={{ fontSize: 15}}/>{message.filename}</IconButton></div>}</div>},format(tempDate, 'HH:mm'),undefined,i)
            message.sender === 'client'
              ? addPreviousUserMessage(
                  message.text,
                  () => {
                    return (
                      <div>
                        {message.type === 'image' ? (
                          <CustomZoom user="client" media_url={message.media_url} />
                        ) : (
                          <div className="client-document">
                            <IconButton
                              edge="start"
                              onClick={() => {
                                window.open(message.media_url);
                              }}
                              style={{ fontSize: '12px' }}
                            >
                              <Description style={{ fontSize: 15 }} />
                              {message.filename}
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  },
                  format(tempDate, 'HH:mm'),
                  message._id,
                  i,
                )
              : addPreviousResponseMessage(
                  message.text,
                  () => {
                    return (
                      <div>
                        {message.type === 'image' ? (
                          <CustomZoom user="response" media_url={message.media_url} />
                        ) : (
                          <div className="response-document">
                            <IconButton
                              edge="start"
                              onClick={() => {
                                window.open(message.media_url);
                              }}
                              style={{ fontSize: '12px' }}
                            >
                              <Description style={{ fontSize: 15 }} />
                              {message.filename}
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  },
                  format(tempDate, 'HH:mm'),
                  message._id,
                  i,
                  undefined,
                  undefined,
                  message.rating,
                );
          else if (message.type === 'interactive') {
            if (message.interactive.type === 'button') {
              const buttons: InteractiveButton[] = message.interactive.action.buttons.map((button) => {
                return {
                  type: InteractiveButtonType.Button,
                  label: button.reply.title,
                  value: button.reply.id,
                  rawButton: button,
                };
              });

              addPreviousResponseMessage(
                message.interactive.body.text,
                undefined,
                format(tempDate, 'HH:mm'),
                message._id,
                i,
                buttons,
                undefined,
                message.rating,
              );
            } else if (message.interactive.type === 'internalMedia') {
              const buttons: InteractiveButton[] = message.interactive.action.buttons.map((internalMediaButton) => {
                return {
                  type: InteractiveButtonType.InternalMediaButton,
                  label: internalMediaButton.internalMedia.title,
                  value: internalMediaButton.internalMedia.key,
                  rawButton: internalMediaButton,
                };
              });
              addPreviousResponseMessage(
                message.interactive.body.text,
                undefined,
                format(tempDate, 'HH:mm'),
                message._id,
                i,
                undefined,
                {
                  prefix: message.interactive.action.prefix ?? '',
                  buttons,
                },
                message.rating,
              );
            }
          } else if (message.type === 'reply') {
            message.sender === 'client'
              ? addPreviousUserMessage(
                  message.reply.button_reply.title,
                  undefined,
                  format(tempDate, 'HH:mm'),
                  message._id,
                  i,
                )
              : addPreviousResponseMessage(
                  message.reply.button_reply.title,
                  undefined,
                  format(tempDate, 'HH:mm'),
                  message._id,
                  i,
                  undefined,
                  undefined,
                  message.rating,
                );
          }

          if (i === data.messages.length - 1 && format(currentDate, 'yyyy-MM-dd') !== format(tempDate, 'yyyy-MM-dd')) {
            const difference = Math.round((currentDate.getTime() - lastDate.getTime()) / (1000 * 3600 * 24));

            renderDayStamp(difference, message.timestamp.slice(0, 10));
          }

          lastDate = tempDate;

          i++;
        });

        setLoadingPMCount(loadingPMCount + 1);
        setTimeStampOffset(lastDate.getTime()); //Prepare for next loadMessages
        setBadgeCount(0);
        togglePreviousMsgLoader();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderDayStamp = (difference, date) => {
    if (difference === 1) renderPreviousCustomComponent(DayStamp, { date: 'Yesterday' });
    else if (difference === 0) renderPreviousCustomComponent(DayStamp, { date: 'Today' });
    else renderPreviousCustomComponent(DayStamp, { date: date });
  };

  const handleInteractiveButtonClicked = (button: InteractiveButton, messageID: string) => {
    const { label, value, type } = button;
    switch (type) {
      case InteractiveButtonType.Button: {
        if (isEndingMessage && !apiClient.isMemberMode) {
          if (`${label}`.toLowerCase() === 'no') {
            dropMessages();
            setIsContactForm(false);
            toggleContactForm();
            postEndSession();

            stompClient.disconnect();
            apiClient.setSessionId('');

            const vt = uuidv4();
            localStorage.setItem('visitorToken', vt);
            apiClient.setVisitorToken(vt);

            setLoadingPMCount(0);
          }

          toggleInputDisabled();
          setQuickButtons([]);

          isEndingMessage = false;
        } else {
          postReplyMessage(label, `${value}`, 'button', messageID);
          addUserMessage(label);
        }
        break;
      }
      case InteractiveButtonType.InternalMediaButton: {
        if (window.parent && parentUrl) {
          window.parent.postMessage(
            {
              event: 'internalMediaButtonClicked',
              payload: button.rawButton.internalMedia,
            },
            parentUrl,
          );
        }
      }
    }
  };

  const handleNewUserMessage = function (this: typeof App, newMessage, plainFiles, index) {
    // loadSession();
    // console.log("file: ", plainFiles);
    if (isNewDay) {
      renderCustomComponent(DayStamp, { date: 'Today' });
      isNewDay = false;
    }

    if (plainFiles === undefined) postMessage(newMessage);
    else {
      // console.log("type: ", plainFiles[0].type.split("/")[0]);
      setIsUploadingFile(true);
      postFile(plainFiles[0], plainFiles[0].type.split('/')[0] === 'image' ? 'image' : 'document', index);
    }

    fi?.setAttribute('href', 'testing.ico');
    // console.log(`New message incoming!`, newMessage);
    // var autoMessage = 'How can we help you today?';
    // isEndingMessage = (newMessage as string).toLowerCase() === ENDING_MESSAGE
    // if (isEndingMessage)
    //   autoMessage = 'Glad it helps! Do you have any further questions and want to continue this chat?';

    // let buttons = [
    //   {
    //     label: 'Yes',
    //     value: 'yes',
    //   },
    //   {
    //     label: 'No',
    //     value: 'no',
    //   },
    // ];

    // setTimeout(function (this: typeof App) {
    //   if (isEndingMessage && !apiClient.isMemberMode) {
    //     toggleInputDisabled();
    //     addResponseMessage(endingMessage);
    //     setQuickButtons(buttons);
    //   }
    // }, 500);

    // if (isEndingMessage && !isEnding) setIsEnding(true);

    //setTimeout(addResponseMessage('Welcome! What can we help you?'),1000);
    //renderCustomComponent(welcomeForm);
    // Now send the message through the backend API
  };

  const handleConversationScroll = (e) => {
    // console.log("lastSessionID: ", lastSessionID);

    e.preventDefault();

    if (apiClient.sessionId !== '' && !isShowingForm && !isUploadingFile) {
      getPreviousMessages();
    }
  };

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <b>Live Chat Demo</b>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          imBee
        </a>
      </header> */}
      {isValidWidget && (
        <Widget
          title={headerTitle}
          subtitle=""
          titleAvatar={headerLogo}
          profileAvatar={avatarImage}
          launcherOpenImg={widgetIcon}
          handleNewUserMessage={handleNewUserMessage}
          senderPlaceHolder={isShowingForm ? 'Complete the form to start chatting...' : 'Type your message...'}
          fullScreenMode={isFullScreenMode}
          showCloseButton={true}
          showTimeStamp={true}
          handleInteractiveButtonClicked={handleInteractiveButtonClicked}
          handleConversationScroll={handleConversationScroll}
          emojis
          widgetConfig={widgetConfig}
          //defaultUserMessage={false}
        />
      )}
    </div>
  );
}

// For Windows desktop notification
// function showNotif() {
//   const notification = new Notification('New incoming message', {
//     body: 'testing',
//     icon: 'https://media-exp1.licdn.com/dms/image/C510BAQEsfo-vyJ0MuQ/company-logo_200_200/0/1580705795883?e=2159024400&v=beta&t=2mJM19b89Jlxp2uAfnXRTQbZar0glbwxdrCIHpj2PTc',
//   });
//   notification.onclick = (e) => {
//     window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
//   };
// }
// console.log(Notification.permission);

// if (Notification.permission === 'granted') {
//   showNotif();
// } else if (Notification.permission !== 'denied') {
//   Notification.requestPermission().then((permission) => {
//     if (permission == 'granted') {
//       showNotif();
//     }
//   });
// }

export const ContactForm = () => {
  //const isShowingForm = useSelector((state)=>state.contactForm.isShowingForm);
  //const isShowingForm = true;z
  //const dispatch = useDispatchs();

  //const isShowingForm = useSelector((state)=>state.contactForm.isShowingForm);
  type FormModel = {
    phone: string;
    option0: string[];
    option1: string[];
    option2: string[];
  };

  const formikRef = useRef<FormikProps<FormModel>>(null);
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const textColor = useSelector((state: GlobalState) => state.responses.textColor);
  const optionFields = useSelector((state: GlobalState) => state.responses.optionFields);
  const [phone, setPhone] = useState('');
  const [phoneFieldIndex, setPhoneFieldIndex] = useState(-1);
  const [isOptionError0, setIsOptionError0] = useState(false);
  const [isOptionError1, setIsOptionError1] = useState(false);
  const [isOptionError2, setIsOptionError2] = useState(false);
  // const option0Options = ["30", "20", "10"];
  // const option1Options = ["Female", "Male", "Gay"];
  // const [option2Options, setoption2Options] = useState(["One", "Two", "Three", "Four", "Five"]);

  useEffect(() => {
    formikRef.current?.setFieldValue('phone', phone);
    // console.log(phone);
  }, [phone]);

  const BlackRadio = withStyles({
    root: {
      color: 'black',
      '&$checked': {
        color: 'black',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const postNewSession = async function (values: Record<string, unknown>) {
    var formValues = {
      [optionFields[0].name]: values.option0,
      [optionFields[1].name]: values.option1,
      [optionFields[2].name]: values.option2,
    };
    // console.log(formValues);

    await apiClient
      .createSession(formValues)
      .then((response) => {
        const data = response.data;
        setStompUser(data.subscribe.visitor_token);
        setPassword(data.subscribe.password);
        setStompUrl(data.subscribe.url);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log('PS response: ', response);
  };

  const getOptionFieldToRender = (optionField: OptionFieldType, values, errors, touched, handleChange, handleBlur) => {
    if (optionField.type === 'select') {
      const error =
        optionFields.indexOf(optionField) === 0
          ? errors.option0
          : optionFields.indexOf(optionField) === 1
          ? errors.option1
          : errors.option2;
      const touch =
        optionFields.indexOf(optionField) === 0
          ? touched.option0
          : optionFields.indexOf(optionField) === 1
          ? touched.option1
          : touched.option2;

      return (
        <FormControl>
          <div style={{ width: '278px', marginTop: '10px', marginBottom: '10px' }}>
            <InputLabel
              style={{ marginLeft: '10px', marginTop: '2px' }}
              id="option0-label"
              error={Boolean(error) && touch}
            >
              {optionField.label}
            </InputLabel>
            <Select
              style={{ borderRadius: '8px 8px 0px 0px', width: '278px' }}
              variant="standard"
              labelId="option0-label"
              name={`option${optionFields.indexOf(optionField)}`}
              value={
                optionFields.indexOf(optionField) === 0
                  ? values.option0
                  : optionFields.indexOf(optionField) === 1
                  ? values.option1
                  : values.option2
              }
              label="Age"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(error) && touch}
              inputProps={{
                style: {
                  padding: '0px 0px',
                  fontSize: '14px',
                },
              }}
            >
              {optionField.options.map((option) => (
                <MenuItem style={{ fontFamily: 'Source Sans Pro' }} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          {/* {error && touch && <FormHelperText>This is required!</FormHelperText>} */}
        </FormControl>
      );
    } else if (optionField.type === 'radio') {
      const error =
        optionFields.indexOf(optionField) === 0
          ? errors.option0
          : optionFields.indexOf(optionField) === 1
          ? errors.option1
          : errors.option2;
      const touch =
        optionFields.indexOf(optionField) === 0
          ? touched.option0
          : optionFields.indexOf(optionField) === 1
          ? touched.option1
          : touched.option2;
      const isOptionError =
        optionFields.indexOf(optionField) === 0
          ? isOptionError0
          : optionFields.indexOf(optionField) === 1
          ? isOptionError1
          : isOptionError2;

      optionFields.indexOf(optionField) === 0
        ? setIsOptionError0(error && touch)
        : optionFields.indexOf(optionField) === 1
        ? setIsOptionError1(error && touch)
        : setIsOptionError2(error && touch);

      return (
        <FormControl>
          <div
            className={`options-wrapper-${isOptionError ? 'error' : 'standard'}`}
            style={{ width: '278px', marginTop: '10px', marginBottom: '10px', borderRadius: '8px' }}
          >
            <div style={{ margin: '10px' }}>
              <FormLabel
                className={`options-label-${isOptionError ? 'error' : 'standard'}`}
                style={{ fontFamily: 'Source Sans Pro', alignSelf: 'center', marginBottom: '10px' }}
                component="legend"
              >
                {optionField.label}
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name={`option${optionFields.indexOf(optionField)}`}
                value={
                  optionFields.indexOf(optionField) === 0
                    ? values.option0
                    : optionFields.indexOf(optionField) === 1
                    ? values.option1
                    : values.option2
                }
                onChange={handleChange}
              >
                {optionField.options.map((option) => (
                  <FormControlLabel
                    className={`options-wrapper-${isOptionError ? 'error' : 'standard'}`}
                    style={{ borderRadius: '0px 8px 8px 0px' }}
                    value={option}
                    control={<BlackRadio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
          {/* {error && touch && <FormHelperText>This is required!</FormHelperText>} */}
        </FormControl>
      );
    } else if (optionField.type === 'checkbox') {
      // console.log("index: ", optionFields.indexOf(optionField))

      const error =
        optionFields.indexOf(optionField) === 0
          ? errors.option0
          : optionFields.indexOf(optionField) === 1
          ? errors.option1
          : errors.option2;
      const touch =
        optionFields.indexOf(optionField) === 0
          ? touched.option0
          : optionFields.indexOf(optionField) === 1
          ? touched.option1
          : touched.option2;
      const isOptionError =
        optionFields.indexOf(optionField) === 0
          ? isOptionError0
          : optionFields.indexOf(optionField) === 1
          ? isOptionError1
          : isOptionError2;

      optionFields.indexOf(optionField) === 0
        ? setIsOptionError0(error && touch)
        : optionFields.indexOf(optionField) === 1
        ? setIsOptionError1(error && touch)
        : setIsOptionError2(error && touch);

      return (
        <FormControl required={optionField.required}>
          <div
            className={`options-wrapper-${isOptionError ? 'error' : 'standard'}`}
            style={{ width: '278px', display: 'flex', flexDirection: 'column', borderRadius: '8px' }}
          >
            <FormLabel
              className={`options-label-${isOptionError ? 'error' : 'standard'}`}
              style={{ fontFamily: 'Source Sans Pro', marginBottom: '10px', marginTop: '10px' }}
              component="legend"
            >
              {optionField.label}
            </FormLabel>
            {optionField.options.map((option) => (
              <div className={`options-border-top-${isOptionError ? 'error' : 'standard'}`} style={{ display: 'flex' }}>
                <Field
                  color="primary"
                  style={{ alignSelf: 'flex-start', marginLeft: '10px', color: 'black' }}
                  type="checkbox"
                  value={option}
                  component={CheckboxWithLabel}
                  name={`option${optionFields.indexOf(optionField)}`}
                  Label={{ label: option, size: 'small' }}
                />
              </div>
            ))}
          </div>
          {/* {error && touch && <FormHelperText>This is required!</FormHelperText>} */}
        </FormControl>
      );
    } else if (optionField.type === 'text' || optionField.type === 'textarea') {
      const error =
        optionFields.indexOf(optionField) === 0
          ? errors.option0
          : optionFields.indexOf(optionField) === 1
          ? errors.option1
          : errors.option2;
      const touch =
        optionFields.indexOf(optionField) === 0
          ? touched.option0
          : optionFields.indexOf(optionField) === 1
          ? touched.option1
          : touched.option2;

      optionFields.indexOf(optionField) === 0
        ? setIsOptionError0(error && touch)
        : optionFields.indexOf(optionField) === 1
        ? setIsOptionError1(error && touch)
        : setIsOptionError2(error && touch);

      return (
        <div className="inputboxes-name" style={{ marginBottom: '15px' }}>
          <TextField
            style={{
              // marginLeft: '36px',
              // marginRight: '36px',
              width: '278px',
              display: 'flex',
              minHeight: '3.0rem',
            }}
            label={optionField.label}
            margin="dense"
            variant="outlined"
            id={`option${optionFields.indexOf(optionField)}`}
            placeholder=""
            type="text"
            value={
              optionFields.indexOf(optionField) === 0
                ? values.option0
                : optionFields.indexOf(optionField) === 1
                ? values.option1
                : values.option2
            }
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              style: {
                padding: '14px 16px',
                fontSize: '14px',
              },
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
              },
            }}
            InputLabelProps={{
              style: {
                //padding: '10.5px',
                marginTop: '4px',
              },
            }}
            FormHelperTextProps={{
              style: {
                marginTop: '0px',
                height: '0px',
                fontFamily: 'Source Sans Pro',
                color: '#e54545',
              },
            }}
            error={Boolean(error) && touch}
            helperText={error && touch && String(error)}
          />
        </div>
      );
    } else if (optionField.label.match(/Phone/i)) {
      setPhoneFieldIndex(optionFields.indexOf(optionField));

      return (
        <div className="inputboxes-phone" style={{ marginBottom: '15px' }}>
          <PhoneInput
            style={{
              // marginLeft: '36px',
              // marginRight: '36px',
              width: '278px',
              display: 'flex',
              minHeight: '3.5rem',
            }}
            id="phone"
            placeholder=""
            type="text"
            value={values.phone}
            onChange={setPhone}
            onBlur={handleBlur}
            inputComponent={CustomPhoneNumber}
            countrySelectComponent={CountrySelectWithIcon}
            error={Boolean(errors.phone) && touched.phone}
            helperText={errors.phone && touched.phone && String(errors.phone)}
            inputProps={{
              style: {
                padding: '14px 16px',
                fontSize: '14px',
              },
            }}
            FormHelperTextProps={{
              style: {
                marginTop: '0px',
                height: '0px',
                fontFamily: 'Source Sans Pro',
                color: '#e54545',
              },
            }}
            InputLabelProps={{
              style: {
                //padding: '10.5px',
                marginTop: '4px',
              },
            }}
          />
        </div>
      );
    }
  };

  //const isShowingForm = true;
  //const dispatch = useDispatch();

  return (
    <div>
      <Formik
        initialValues={{ phone: '', option0: [], option1: [], option2: [] }}
        onSubmit={async (values) => {
          //const that = this;
          await new Promise((resolve) => setTimeout(resolve, 500));
          // alert(JSON.stringify(values, null, 2));
          //toggleInputDisabled();
          //this.props.onSubmitChange();
          //dispatch({type: Action.IS_SUBMITTED});
          toggleContactForm();
          toggleInputDisabled();
          postNewSession(values);
          renderCustomComponent(LoaderSpinner);
          //dropMessages();
          // console.log(`current state: `, isShowingForm);
          // console.log("option0 length: ", values.option0.length);
          // console.log(values.option0)
          // console.log("option1 length: ", values.option1.length);
          // console.log(values.option1)
          // console.log("option2 length: ", values.option2.length);
          // console.log(values.option2)

          //this.setState({ showingForm: false});
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string()
          //   .min(2, 'Too Short!')
          //   .max(50, 'Too Long!')
          //   .required('Invalid user name')
          //   .matches(/^[a-zA-Z\s]*$/, 'Please do not enter symbols'),
          // email: Yup.string()
          //   .email('Please enter the correct email address')
          //   .required('Please enter the correct email address'),
          // phone: Yup.string()
          //   .phone(undefined, undefined, 'Please enter the correct number')
          //   .required('Please enter the correct number'),

          phone: Yup.lazy((phone) => {
            return optionFields[phoneFieldIndex]
              ? optionFields[phoneFieldIndex].required
                ? Yup.string()
                    .phone(undefined, undefined, 'Please enter the correct number')
                    .required('Please enter the correct number')
                : Yup.string().phone(undefined, undefined, 'Please enter the correct number')
              : Yup.string();
          }),
          // option0: Yup.array().min(2),
          option0: Yup.lazy((option0) => {
            return Array.isArray(option0)
              ? Yup.array().min(optionFields[0].required ? 1 : 0, 'Required')
              : // optionFields[0].label.match(/Name/i)? Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').min(optionFields[0].required?1:0).matches(/^[a-zA-Z\s]*$/, 'Please do not enter symbols'):
              optionFields[0].label.match(/Name/i)
              ? Yup.string()
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[0].label.match(/Email/i)
              ? Yup.string()
                  .email('Please enter the correct email address')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[0].required
              ? Yup.string().required('Required')
              : Yup.string(); //Checked radio & select values will become string([]->String)
          }),
          option1: Yup.lazy((option1) => {
            return Array.isArray(option1)
              ? Yup.array().min(optionFields[1].required ? 1 : 0, 'Required')
              : optionFields[1].label.match(/Name/i)
              ? Yup.string()
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[1].label.match(/Email/i)
              ? Yup.string()
                  .email('Please enter the correct email address')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[1].required
              ? Yup.string().required('Required')
              : Yup.string();
          }),
          option2: Yup.lazy((option2) => {
            return Array.isArray(option2)
              ? Yup.array().min(optionFields[2].required ? 1 : 0, 'Required')
              : optionFields[2].label.match(/Name/i)
              ? Yup.string()
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[2].label.match(/Email/i)
              ? Yup.string()
                  .email('Please enter the correct email address')
                  .min(optionFields[0].required ? 1 : 0)
              : optionFields[2].required
              ? Yup.string().required('Required')
              : Yup.string();
          }),
          // option0: Yup.array().test(arr => arr?.length !== 0),
          // option1: Yup.when("isOption1Array", {is: true, then: Yup.array().min(1), otherwise: Yup.string().min(1)})
          // option2: Yup.array().of(Yup.string().required())
        })}
        innerRef={formikRef}
        enableReinitialize={true}
      >
        {(props) => {
          const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FadeIn>
                <h3 className="Welcome">Welcome!</h3>
                <h5 className="Please-complete-the">
                  {optionFields[0].type === ''
                    ? 'Push the button to start chatting!'
                    : 'Please complete the form to start chatting!'}
                </h5>

                {/* <div className="inputboxes-name" style={{ marginBottom: '15px' }}>
                    <TextField
                      style={{
                        marginLeft: '36px',
                        marginRight: '36px',
                        // height: '40px',
                        display: 'flex',
                        // fontSize: '2px',
                        minHeight: "3.0rem"
                      }}
                      label="Name"
                      margin="dense"
                      variant="outlined"
                      id="name"
                      placeholder=""
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        style: {
                          padding: '14px 16px',
                          fontSize: '14px'
                        }
                      }}
                      InputProps={{
                        style:{
                          borderRadius: '8px'
                        }
                      }}
                      InputLabelProps={{
                        style: {
                          //padding: '10.5px',
                          marginTop: '4px'
                        }
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginTop: '0px',
                          height: '0px',
                          fontFamily: 'Source Sans Pro', 
                          color: '#e54545',
                        },
                      }}
                      error={Boolean(errors.name) && touched.name}
                      helperText={errors.name && touched.name && String(errors.name)}
                    />
                  </div>
                  <div className="inputboxes-phone" style={{ marginBottom: '15px', width: '350px' }}>
                    <PhoneInput
                      style={{
                        marginLeft: '36px',
                        marginRight: '36px',
                        display: 'flex',
                        minHeight: "3.5rem"
                      }}
                      id="phone"
                      placeholder=""
                      type="text"
                      value={values.phone}
                      onChange={setPhone}
                      onBlur={handleBlur}
                      inputComponent={CustomPhoneNumber}
                      countrySelectComponent={CountrySelectWithIcon}
                      error={Boolean(errors.phone) && touched.phone}
                      helperText={errors.phone && touched.phone && String(errors.phone)}
                      inputProps={{
                        style: {
                          padding: '14px 16px',
                          fontSize: '14px'
                        }
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginTop: '0px',
                          height: '0px',
                          fontFamily: 'Source Sans Pro',
                          color: '#e54545',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          //padding: '10.5px',
                          marginTop: '4px'
                        }
                      }}
                    />
                  </div>
                  <div className="inputboxes-email" style={{ marginBottom: '15px' }}>
                    <TextField
                      style={{
                        marginLeft: '36px',
                        marginRight: '36px',
                        display: 'flex',
                        minHeight: '2.8rem',
                      }}
                      label="Email"
                      margin="dense"
                      variant="outlined"
                      id="email"
                      placeholder=""
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        style: {
                          padding: '14px 16px',
                          fontSize: '14px',
                        },
                      }}
                      InputProps={{
                        style:{
                          borderRadius: '8px'
                        }
                      }}
                      error={Boolean(errors.email) && touched.email}
                      helperText={errors.email && touched.email && String(errors.email)}
                      FormHelperTextProps={{
                        style: {
                          marginTop: '0px',
                          height: '0px',
                          fontFamily: 'Source Sans Pro',
                          color: '#e54545',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          //padding: '10.5px',
                          marginTop: '4px'
                        }
                      }}
                    />
                  </div> */}
                {/* <div className="option0" style={{zIndex: 51}}> */}

                {optionFields.map((optionField) => (
                  <div>{getOptionFieldToRender(optionField, values, errors, touched, handleChange, handleBlur)}</div>
                ))}

                {/* <FormControl>
                  <div style={{width: "278px"}}>
                  <InputLabel style={{marginLeft: "10px", marginTop: "-8px"}} id="option0-label" >Age</InputLabel>
                  <Select
                    style={{ borderRadius: "8px", width: "278px"}}
                    variant="outlined"
                    labelId="option0-label"
                    id="option0"
                    name="option0"
                    value={values.option0}
                    label="Age"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      style: {
                        padding: '0px 0px',
                        fontSize: '14px',
                      },
                    }}     
                  >
                    {option0Options.map((option) => (
                      <MenuItem style={{fontFamily: 'Source Sans Pro'}} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                  </div>
                  </FormControl>

                  <FormControl>
                  <div style={{width: "278px", marginTop: "25px", marginBottom: "20px", borderRadius: "10px", border: '1px solid #cecece', boxShadow: "10px 10px 10px 10px $grey-3"}}>
                  <div style={{margin: "10px"}}>
                  <FormLabel style={{fontFamily: 'Source Sans Pro', alignSelf: 'center', marginBottom: "10px"}} component="legend">Gender</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="option1"
                    value={values.option1}
                    onChange={handleChange}
                  >
                    {option1Options.map((option) =>(
                      <FormControlLabel style={{border: '1px solid #cecece', borderRadius: '0px 8px 8px 0px'}} value={option} control={<BlackRadio/>} label={option} />
                    ))}
                  </RadioGroup>
                  </div>
                  </div>
                  </FormControl>
                  
                  <FormControl>
                  <FormLabel style={{fontFamily: 'Source Sans Pro', marginBottom: "10px"}} component="legend">Numbers</FormLabel>
                  
                  <div style={{ width: "278px", display: "flex", flexDirection: "column", border: '1px solid #cecece', borderRadius: '8px'}}>
                  {option2Options.map((option) => (
                    
                    <Field style={{alignSelf: "flex-start", marginLeft: "10px", color: "black"}} type="checkbox" value={option} component={CheckboxWithLabel } name="option2" Label={{ label: option, size: "small" }} />
                    
                  ))}</div>
                  </FormControl> */}

                {/* </div> */}
                <div>
                  {/* <Button
                      variant="contained"
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    className="outline"
                    disabled={isSubmitting}
                    style={{
                      marginBottom: '40px',
                      marginTop: '20px',
                      paddingBottom: '6px',
                      paddingTop: '6px',
                      width: '100%',
                      backgroundColor: `#${themeColor}`,
                      color: textColor,
                    }}
                    onClick={() => {
                      if (errors.phone) setPhone('');
                    }}
                  >
                    {optionFields[0].type === '' ? 'Start' : 'Submit'}
                  </Button>
                </div>
              </FadeIn>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export const PostChatSurvey = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  // const [charCount, setCharCount] = useState(0);
  const [isEmptyRating, setIsEmptyRating] = useState(false);

  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const textColor = useSelector((state: GlobalState) => state.responses.textColor);

  return (
    <div className="survey-container">
      <div>
        {/* <p className="feedback-text">The chat history has been sent to your email.</p> */}
        <p className="feedback-text-bolded">How was your experience?</p>

        <div className="star-container">
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <label key={i}>
                <input type="radio" name="rating" value={ratingValue} onClick={() => setRating(ratingValue)} />
                <IoMdStar
                  className="star"
                  size={24}
                  color={
                    ratingValue <= (hover || rating) ? '#ffe063' : 'white'
                    // ratingValue <= rating ? '#ffe063' : '#ffffff'
                  }
                  onMouseEnter={() => {
                    setHover(ratingValue);
                    setIsEmptyRating(false);
                  }}
                  onMouseLeave={() => setHover(0)}
                />
              </label>
            );
          })}
          {/* <p>You rated {rating + "/5"} </p> */}
        </div>
        {isEmptyRating && <p style={{ fontSize: '14px', color: '#e54545' }}>Please provide a rating</p>}
        {/* <div className='feedback-container'>
          <label htmlFor="feedback-box" className='feedback-title'>Feedback</label>
            <textarea
              className="feedback-box"
              placeholder="Additional comments (optional)"
              maxLength={500}
              onChange={(e) => setCharCount(e.target.value.length)}
            />
          </div> 
          <hr style={{ backgroundColor: '#cecece', border: '1px solid #cecece' }} />
          <p className="char-count">{charCount + '/500'}</p>*/}
        <Button
          variant="contained"
          className="submit-survey"
          onClick={() => {
            if (rating === 0) setIsEmptyRating(true);
            else {
              dropMessages();
              renderCustomComponent(SurveySubmitted);
            }
          }}
          style={{ backgroundColor: `#${themeColor}`, color: textColor }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export const SurveySubmitted = (props) => {
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const textColor = useSelector((state: GlobalState) => state.responses.textColor);

  return (
    <div className="confirmation-container">
      <div className="submit-confirmation">
        <img src={farewell} className="submit-checkmark" alt="checkmark" />
        <p style={{ fontWeight: 'bold' }}> Chat Ended </p>
        {/* <p style={{fontSize: '13px'}}>Thanks for completing the survey!</p> */}
        <p style={{ fontSize: '13px' }}>Thank you for chatting with us!</p>
        <Button
          style={{ textTransform: 'none', backgroundColor: `#${themeColor}`, color: textColor }}
          variant="contained"
          className="to-contact-form"
          onClick={props.renderContactForm}
        >
          Return to Contact Form
        </Button>
      </div>
    </div>
  );
};

export const LoaderSpinner = () => {
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);

  // console.log("loading...")
  return (
    <div>
      <Loader
        type="ThreeDots"
        color={`#${themeColor}`}
        height={100}
        width={100}
        timeout={10000} //3 secs
      />
    </div>
  );
};

export const ConnectionErrorPrompt = () => {
  return (
    <div className="error-container">
      <div className="submit-confirmation">
        <img src={disconnected} className="disconnected-checkmark" alt="checkmark" />
        <p style={{ fontWeight: 'bold' }}> Connection Failed </p>
        {/* <p style={{fontSize: '13px'}}>Thanks for completing the survey!</p> */}
      </div>
    </div>
  );
};

export const DayStamp = (props) => {
  return (
    <div
      style={{
        textTransform: 'none',
        backgroundColor: '#dcdce4',
        color: '#909093',
        borderRadius: '15px',
        width: '80px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p>{props.date}</p>
    </div>
  );
};

export const CustomZoom = (props) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);

    if (window.parent && parentUrl) {
      window.parent.postMessage(shouldZoom ? 'isZoomingImage' : 'isNotZoomingImage', parentUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange} zoomMargin={100}>
      <div className={`${props.user}-document`}>
        <img alt="" src={props.media_url} style={{ width: isZoomed ? '100%' : isFullScreenMode ? '100%' : '50%' }} />
      </div>
    </ControlledZoom>
  );
};

export default App;

// const SubmitHandle = () => {
// const [showSurvey, setShowSurvey] = useState(true);
// const onClick = () => setShowSurvey(false)

// return (
// <input type="submit" value="Submit" onClick={onClick} />
// { showSurvey ? <PostChatSurvey /> : null}
// )
// }

/*
class welcomeForm extends Component{
		
	  constructor(props) {
		  
		super(props);
		//this.state={showingForm: props.showingForm};
		
	  }
	
	  
	  componentWillReceiveProps(nextProps){
		  
		  this.setState({ showingForm: nextProps.showingForm });
		  
	  }
	  
	  
	  render() {
		const phoneRegExp = "/^1[0-9]{10}$|^[569][0-9]{7}$/"
		//const {showingForm} = this.state
		return (
			<div>
			{this.props.showingForm? <Formik
			  
			  initialValues={{ email: "", phone: "", name: ""}}
			  onSubmit={async values => {
				const that = this;
				await new Promise(resolve => setTimeout(resolve, 500));
				alert(JSON.stringify(values, null, 2));
				toggleInputDisabled();
				this.props.onSubmitChange();
				console.log(`current state: `,this.props.showingForm);
				//this.setState({ showingForm: false});
			  }}
			  validationSchema={Yup.object().shape({
				name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required("Invalid user name"),
				email: Yup.string().email().required("Invalid email address"),
				phone: Yup.string().phone('HK').required("Invalid phone number")
			  })}
			>
			  {props => {
				const {
				  values,
				  touched,
				  errors,
				  dirty,
				  isSubmitting,
				  handleChange,
				  handleBlur,
				  handleSubmit,
				  handleReset
				} = props;
				return (
				
				  
				  <form onSubmit={handleSubmit}>
					<FadeIn>
					
					<label className="email" for="rg-from" htmlFor="name" style={{ display: "flex", marginLeft: "10px"}}>
					
					  Name: 
					  
					  <input style={{ marginLeft: "14px", marginRight: "80px", display: "flex"}}
					  id="name"
					  placeholder=""
					  type="text"
					  value={values.name}
					  onChange={handleChange}
					  onBlur={handleBlur}
					  className={
						errors.name && touched.name
						  ? "text-input error"
						  : "text-input"
					  }
					  />
					  
					</label>
					
					{errors.name && touched.name && (
					  <div className="input-feedback">{errors.name}</div>
					)}
					
					<label className="email" for="rg-from" htmlFor="email" style={{ display: "flex", marginLeft: "10px"}}>
					
					  Email: 
					  
					  <input style={{ marginLeft: "18px", marginRight: "80px", display: "flex"}}
					  id="email"
					  placeholder=""
					  type="text"
					  value={values.email}
					  onChange={handleChange}
					  onBlur={handleBlur}
					  className={
						errors.email && touched.email
						  ? "text-input error"
						  : "text-input"
					  }
					  />
					  
					</label>
					
					{errors.email && touched.email && (
					  <div className="input-feedback">{errors.email}</div>
					)}
					
					<label className="email" for="rg-from" htmlFor="phone" style={{ display: "flex", marginLeft: "10px"}}>
					
					  Phone: 
					  
					  <input style={{ marginLeft: "10px", marginRight: "80px", display: "flex"}}
					  id="phone"
					  placeholder=""
					  type="text"
					  value={values.phone}
					  onChange={handleChange}
					  onBlur={handleBlur}
					  className={
						errors.email && touched.email
						  ? "text-input error"
						  : "text-input"
					  }
					  />
					
					</label>
					
					{errors.phone && touched.phone && (
					  <div className="input-feedback">{errors.phone}</div>
					)}						
					
					<div>
					<button
					  type="button"
					  className="outline"
					  onClick={handleReset}
					  disabled={!dirty || isSubmitting}
					>
					  Reset
					</button>
					<button type="submit" className="outline" disabled={isSubmitting}>
					  Submit
					</button>
					</div>
					</FadeIn>
				  </form>
				  
				);
			  }}
		</Formik> : null}
		</div>
		);
	  }
}

class app extends Component{
	
	constructor(props) {
        super(props);
		this.state = {showingForm: true};
		this.handleOnSubmitForm = this.handleOnSubmitForm.bind(this);
    }
	
	handleOnSubmitForm(){
		
		this.setState({ showingForm: false});
		console.log(`state is setted: `,this.state.showingForm);
		
	}
	
	handleNewUserMessage(message){
		
		console.log(`New message incoming!` , message);
		//await this.timeout(1000);
		setTimeout(function(){addResponseMessage('Welcome! What can we help you?')}.bind(this),500);
		
	}
	
	componentDidMount(){
		
		//const {showingForm} = this.state;
		//let handleOnSubmitForm = this.handleOnSubmitForm;
		toggleInputDisabled();
		renderCustomComponent(welcomeForm, {showingForm:this.state.showingForm, onSubmitChange:this.handleOnSubmitForm.bind(this)});
		
	}
	
	componentDidUpdate(prevState){
		
		if(prevState.showingForm !== this.state.showingForm){
			
			console.log(`state is setted: `,this.state.showingForm);
			dropMessages();
			//renderCustomComponent(welcomeForm, {showingForm:this.state.showingForm, onSubmitChange:this.handleOnSubmitForm.bind(this)});
		
		}
	}
	
	render(){
		return(
		
			<div className="App">
			  <header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<b>
				  Live Chat Demo
				</b>
				<a
				  className="App-link"
				  href="https://reactjs.org"
				  target="_blank"
				  rel="noopener noreferrer"
				>
				  imBee
				</a>
			  </header>
			  <Widget  
			  subtitle = "imBee Team"
			  handleNewUserMessage={this.handleNewUserMessage}
			  senderPlaceHolder = "Complete the form to start chating!"
			  defaultUserMessage={false}
			  />
			  
			 
			</div>
		)
	}
}

*/
