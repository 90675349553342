import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import format from 'date-fns/format';

//import { scrollToBottom } from "../../../../../../utils/messages";
import {
  MessageTypes,
  QuickButton,
  Link,
  CustomCompMessage,
  GlobalState,
  MessageRatingType,
} from '../../../../../../store/types';
import { setBadgeCount, markAllMessagesRead } from '../../../../../../store/actions';

import Loader from './components/Loader';
import QuickButtons from '../../components/QuickButtons';
import './styles.scss';
import '../QuickButtons/style.scss';
import { AnyFunction } from '../../../../../../../src/utils/types';

type TypingIndicatorObject = {
  key: string;
  stage?: string;
  partialContent?: string;
};

type Props = {
  showTimeStamp: boolean;
  profileAvatar?: string;
  onInteractiveButtonClicked?: AnyFunction;
  onHandleConversationScroll?: AnyFunction;
  fullScreenMode: boolean;
  handleRating: (messageId: string, type: MessageRatingType) => void;
};

function Messages({
  profileAvatar,
  showTimeStamp,
  onInteractiveButtonClicked,
  onHandleConversationScroll,
  fullScreenMode,
  handleRating,
}: Props) {
  const dispatch = useDispatch();
  const { messages, typing, showChat, badgeCount, typingIndicatorExpiries, ratingEnabled } = useSelector(
    (state: GlobalState) => ({
      messages: state.messages.messages,
      badgeCount: state.messages.badgeCount,
      typing: state.behavior.messageLoader,
      showChat: state.behavior.showChat,
      typingIndicatorExpiries: state.messages.typingIndicatorExpiries,
      ratingEnabled: state.messages.ratingEnabled,
    }),
  );

  const isShowingForm = useSelector((state: GlobalState) => state.behavior.showContactForm);
  const isLoadingPreviousMessages = useSelector((state: GlobalState) => state.behavior.isLoadingPreviousMessages);

  const messageRef = useRef<HTMLDivElement | null>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(Date.now());

  const activeTypingIndicators = useMemo(() => {
    const result: TypingIndicatorObject[] = [];
    const expires = typingIndicatorExpiries.filter((exp) => exp.expiry > currentTimestamp);
    let hasRaw = false;
    for (let i = 0; i < expires.length; i++) {
      const { responseIdentifier, stage, partialContent } = expires[i];
      if (!stage && !partialContent) {
        if (!hasRaw) {
          result.push({ key: 'raw' });
          hasRaw = true;
        }
      } else {
        result.push({
          key: responseIdentifier,
          stage,
          partialContent,
        });
      }
    }
    if (typing && !hasRaw) {
      result.push({ key: 'raw' });
    }
    return result;
  }, [typing, currentTimestamp, typingIndicatorExpiries]);

  const typingIndicatorContent = useMemo(() => {
    return JSON.stringify(activeTypingIndicators);
  }, [activeTypingIndicators]);

  const timestampTimer = useRef<number | null>(null);
  useEffect(() => {
    const refreshTimestamp = () => {
      setCurrentTimestamp(Date.now());
      timestampTimer.current = window.setTimeout(refreshTimestamp, 1000);
    };
    refreshTimestamp();
    return () => {
      timestampTimer.current && clearTimeout(timestampTimer.current);
    };
  }, []);

  useEffect(() => {
    if (typingIndicatorContent && messageContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = messageContainerRef.current;
      console.log(scrollTop, clientHeight, scrollHeight, scrollHeight - clientHeight, scrollTop + 100);
      if (scrollHeight - clientHeight <= scrollTop + 100) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  }, [typingIndicatorContent, messageContainerRef]);

  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onScroll = (e) => {
    if (e.target.scrollTop === 0) {
      onHandleConversationScroll?.(e);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (!isShowingForm && !isLoadingPreviousMessages)
      setTimeout(() => {
        scrollToBottom();
      }, 400);

    if (showChat && badgeCount) dispatch(markAllMessagesRead());
    else dispatch(setBadgeCount(messages.filter((message) => message.unread).length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, badgeCount, showChat]);

  const getComponentToRender = (message: MessageTypes | Link | CustomCompMessage) => {
    // console.log("message.quickButtons: ", message.quickButtons);
    const ComponentToRender = message.component;
    if (message.type === 'component') {
      return <ComponentToRender {...message.props} />;
    }
    return (
      <ComponentToRender
        message={message}
        showTimeStamp={showTimeStamp}
        onInteractiveButtonClicked={onInteractiveButtonClicked}
        handleRating={handleRating}
      />
    );
  };

  const getQuickButtonsToRender = (button: QuickButton, messageID: string | undefined) => {
    // console.log("button: ", button);
    const ComponentToRender = button.component;
    return (
      <ComponentToRender
        onInteractiveButtonClicked={onInteractiveButtonClicked}
        button={button}
        messageID={messageID}
      />
    );
  };

  // TODO: Fix this function or change to move the avatar to last message from response
  // const shouldRenderAvatar = (message: Message, index: number) => {
  //   const previousMessage = messages[index - 1];
  //   if (message.showAvatar && previousMessage.showAvatar) {
  //     dispatch(hideAvatar(index));
  //   }
  // }

  return (
    <div
      ref={messageContainerRef}
      id="messages"
      className="rcw-messages-container"
      onScroll={onScroll}
      style={!fullScreenMode ? { height: isShowingForm ? '450px' : 'calc(450px - 55px)' } : { flex: 'auto' }}
    >
      {messages?.map((message, index) => (
        <div
          className={
            message.type === 'component'
              ? 'rcw-component'
              : message.quickButtons !== undefined
              ? `rcw-message-buttons ${ratingEnabled ? 'rating-enabled' : ''}`
              : `rcw-message ${ratingEnabled ? 'rating-enabled' : ''}`
          }
          key={`${index}-${format(new Date(message.timestamp), 'hh:mm')}`}
        >
          {profileAvatar && message.showAvatar && <img src={profileAvatar} className="rcw-avatar" alt="profile" />}

          {/* <div className={""}> */}

          {getComponentToRender(message)}
          {message.quickButtons && (
            <>
              <div style={{ width: '100%' }} />
              <div>
                {/* <div style={{width: "100%"}}/> */}
                <div className="quick-buttons-container">
                  <ul className="quick-buttons">
                    {message.quickButtons?.map((button, index) => (
                      <li className="quick-list-button" key={`${button.label}-${index}`}>
                        {getQuickButtonsToRender(button, message.customId)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}

          {/* </div> */}
        </div>
      ))}

      <QuickButtons onInteractiveButtonClicked={onInteractiveButtonClicked} />
      {activeTypingIndicators.map((obj) => (
        <Loader key={obj.key} typing img={profileAvatar} stage={obj.stage} partialContent={obj.partialContent} />
      ))}
      <div ref={messageRef} />
    </div>
  );
}

export default Messages;
