import { call, put, takeEvery } from 'redux-saga/effects';
import { PUT_MESSAGE_RATING_STARTED, PutMessageRatingStarted } from '../actions/types';
import apiClient from '../../apiClient';
import { putMessageRatingSucceeded, putMessageRatingFailed } from '../actions';

function* putMessageRating({ messageId, rating }: PutMessageRatingStarted) {
    try {
        yield call(apiClient.putMessageRating.bind(apiClient), {
            id: messageId,
            ...rating,
        });
        yield put(putMessageRatingSucceeded(messageId, rating));
    } catch (err) {
        console.log(err);
        yield put(putMessageRatingFailed(messageId, `Rating submission failed: ${(err as Error).message}`));
    }
}

export function* messagesSaga() {
    yield takeEvery(PUT_MESSAGE_RATING_STARTED, putMessageRating);
}