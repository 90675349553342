import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState, QuickButton } from 'src/store/types';
import { AnyFunction } from 'src/utils/types';

import './style.scss';

type Props = {
  onInteractiveButtonClicked?: AnyFunction;
}

function QuickButtons({ onInteractiveButtonClicked }: Props) {
  const buttons = useSelector((state: GlobalState) => state.quickButtons.quickButtons);
  const quickButtonRef = useRef<HTMLDivElement | null>(null);

  const getComponentToRender = (button: QuickButton) => {
    const ComponentToRender = button.component;
    return (
      <ComponentToRender
        onInteractiveButtonClicked={onInteractiveButtonClicked}
        button={button}
        messageID=""
      />
    );
  }
  

  const scrollToBottom = () => {
    quickButtonRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(()=>{
    scrollToBottom()
  }, [buttons])

  if (!buttons.length) return null;

  return (
    <div className="quick-buttons-container">
      <ul className="quick-buttons">
        {buttons.map((button, index) =>
          <li className="quick-list-button" key={`${button.label}-${index}`}>
            {getComponentToRender(button)}
          </li>
          )
        }
      </ul>
      <div ref={quickButtonRef}/>
    </div>
  );
}

export default QuickButtons;
