import './styles.scss';
import { InteractiveButton, InternalMediaButton as InternalMediaButtonType } from '@types';
import KnowledgeBaseDocumentIcon from '../../../../../../../../assets/internal-media-knowledge-base-document-icon.png';

export type Props = {
  button: InternalMediaButtonType;
  onInteractiveButtonClicked: (event: any, button: InteractiveButton, messageID: string | undefined) => any;
};

function InternalMediaButton({ button, onInteractiveButtonClicked }: Props) {

  const renderButtonContent = () => {
    switch(button.interactiveButton.rawButton.internalMedia.type) {
      case 'KnowledgeBaseDocument':
        return (
          <>
            <div className="internal-media-button__icon" style={{ width: 14, height: 14 }}>
              <img src={KnowledgeBaseDocumentIcon} width={14} height={14} alt="" />
            </div>
            <div className="internal-media-button__label">
              {button.label}
              {button.interactiveButton.rawButton.internalMedia.knowledgeBaseDocument?.page &&
                ` - p.${button.interactiveButton.rawButton.internalMedia.knowledgeBaseDocument?.page}`}
            </div>
          </>
        );
      default:
        return <div className="internal-media-button__label">{button.label}</div>;
    }
  };

  return (
    <button className="internal-media-button" onClick={(event) => onInteractiveButtonClicked(event, button.interactiveButton, '')}>
      {renderButtonContent()}
    </button>
  );
}

export default InternalMediaButton;
