import { MessageRatingType } from '@types';
import axios, { AxiosInstance } from 'axios';
class ApiClient {

    client: AxiosInstance;

    isMemberMode: boolean = false;

    widgetToken: string | null = null;
    sessionId: string | null = null;
    visitorToken: string | null = null;
    
    nonce: string | null = null;

    accessToken: string | null = null;
    refreshToken: string | null = null;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_WEB_WIDGET_URL || '',
        });
        this.client.interceptors.request.use((config) => {
            const defaultConfig = this.config;
            return {
                ...config,
                headers: {
                    ...defaultConfig.headers,
                    ...(config.headers ?? {}),
                },
                params: {
                    ...defaultConfig.params,
                    ...(config.params ?? {}),
                },
            };
        });
    }

    setIsMemberMode(flag: boolean) {
        this.isMemberMode = flag;
    }

    setWidgetToken(token: string) {
        this.widgetToken = token;
    }

    setSessionId(id: string) {
        this.sessionId = id;
    }

    setVisitorToken(token: string) {
        this.visitorToken = token;
    }

    setNonce(nonce: string) {
        this.nonce = nonce;
    }
    
    setAccessToken(token: string) {
        this.accessToken = token;
    }

    setRefreshToken(token: string) {
        this.refreshToken = token;
    }

    get config() {
        return {
            headers: {
                "x-imbee-web-chat-token": this.widgetToken,
            },
            params: {
                "session_id": this.sessionId,
                "visitor_token": this.visitorToken,
                // "access_token": this.accessToken,
            }
        };
    }

    async fetchWidget() {
        return this.client.get('/widget')
    }

    async fetchVisitorNonce() {
        const response = await this.client.post('/auth/visitor', {
            visitor_token: this.visitorToken,
        });
        if(response.data?.nonce) {
            this.setNonce(response.data.nonce);
        }
        if(!this.isMemberMode && !response.data?.exist_session) {
            this.setSessionId('');
        }
    }

    async fetchAccessToken() {
        const payload = this.isMemberMode
            ? {
                nonce: this.nonce,
                unique_id: this.visitorToken,
            }
            : {
                nonce: this.nonce,
                visitor_token: this.visitorToken,
            };

        const response = await this.client.post('/auth/token', payload);
        const data = response.data;
        this.setSessionId(data.session_id || '');
        this.setAccessToken(data.access_token || '');
        this.setRefreshToken(data.refresh_token || '');
    }

    async refreshAccessToken() {
        const response = await this.client.post('/auth/refresh', {
            access_token: this.accessToken,
            refresh_token: this.refreshToken,
        });
        this.setAccessToken(response.data.access_token || '');
        this.setRefreshToken(response.data.refresh_token || '');
    }

    async loadSession() {
        const response = await this.client.post(`/sessions/${this.sessionId}/restore`, {
            visitor_token: this.visitorToken,
        }).catch(err => {
            this.setSessionId('')
            throw err;
        });
        return response;
    }

    async sendTextMessage(text: string, senderIdentifier: string) {
        return this.client.post('/message', {
            text: text,
            sender_identifier: senderIdentifier,
            visitor_token: this.visitorToken
        }).catch(err => {
            console.log(err);
            throw err;
        });
    }

    async sendReplyMessage(title: string, id: string, targetType: string, targetId: string, senderIdentifier: string) {
        return this.client.post('/message/reply', {
            reply: {
                target_type: targetType,
                target_id: targetId,
                button_reply: {
                    id: id,
                    title: title
                }
            },
            visitor_token: this.visitorToken,
            sender_identifier: senderIdentifier
        }).catch(err => {
            console.log(err);
            throw err;
        });
    }

    async sendMediaMessage(file: File, type: string, senderIdentifier: string) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        formData.append("visitor_token", this.visitorToken || '');
        formData.append("sender_identifier", senderIdentifier);
        return this.client.post('/message/upload', formData).catch(err => {
            console.log(err);
            throw err;
        });
    }

    async endSession() {
        return this.client.post(`/sessions/${this.sessionId}/end`, {
            visitor_token: this.visitorToken,
        }).catch((err) => {
            console.log(err);
        });
    }

    async fetchMessages(offsetTimestamp?: number) {
        return this.client.get('/message', {
            params: {
                ...(offsetTimestamp ? {
                    offset_timestamp: offsetTimestamp / 1000
                } : {}),
            },
        });
    }

    async createSession(formValues: Record<string, any>) {
        return this.client.post('/sessions', {
            form: formValues,
            visitor_token: this.visitorToken,
        }).then(response => {
            const data = response.data;
            this.setSessionId(data.session_id || '');
            this.setAccessToken(data.access_token || '');
            this.setRefreshToken(data.refresh_token || '');
            return response;
        });
    }

    async putMessageRating({id, type, comment}: {id: string, type: MessageRatingType, comment?: string}) {
        return this.client.put(`/message/${id}/ratings`, {
            type,
            ...(comment ? {
                comment,
            }: {}),
        });
    }
}

const client = new ApiClient();

export default client;