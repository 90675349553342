import { useRef, useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useFilePicker } from 'use-file-picker-rp';
// import { useFilePicker } from './FilePicker'
import {Icon, IconButton} from '@material-ui/core';
import {Cancel, Description} from '@material-ui/icons';
import {ToastContainer, toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import {isMobile} from 'react-device-detect';

import { ComposerComponent, GlobalState } from 'src/store/types';
import { getCaretIndex, isFirefox, updateCaret, insertNodeAtCaret, getSelection } from '../../../../../../utils/contentEditable'
import { CustomZoom } from 'src/App';

import './style.scss';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import send from "../../../../../../assets/send-outline.svg";
import emoji from "../../../../../../assets/smile.svg";
import clip from '../../../../../../assets/clip.svg'

const brRegex = /<br>/g;
//const send = require('../../../../../../assets/send_button.svg') as string;

type Props = {
  placeholder: string;
  disabledInput: boolean;
  autofocus: boolean;
  sendMessage: (event: any, event2?: any, event3?: any) => void;
  sendCustomComponent: (event: any) => void;
  buttonAlt: string;
  onPressEmoji: () => void;
  onChangeSize: (event: any) => void;
  onTextInputChange?: (event: any) => void;
};

function Sender(
  {
    sendMessage,
    sendCustomComponent,
    placeholder,
    disabledInput,
    autofocus,
    onTextInputChange,
    buttonAlt,
    onPressEmoji,
    onChangeSize,
  }: Props,
  ref
) {
  const defaultSpanHeight = "25px"
  const attachmentSpanHeight = "100px"
  const [spanHeight, setSpanHeight] = useState(defaultSpanHeight);
  //const [spanOverflow, setSpanOverFlow] = useState("auto");
  const [spanDisabledInput, setSpanDisabledInput] = useState(disabledInput);
  const [enter, setEnter] = useState(false);
  const [firefox, setFirefox] = useState(false);
  const [height, setHeight] = useState(0);
  const [numOfNewline, setNumOfNewline] = useState(1);
  const [isAttaching, setIsAttaching] = useState(false);
  //const [prevRowCount, setPrevRowCount] = useState(-1);

  const showChat = useSelector((state: GlobalState) => state.behavior.showChat);
  const isShowingForm = useSelector((state: GlobalState) => state.behavior.showContactForm );
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const disabledComponents = useSelector((state: GlobalState) => state.messages.disableComposerComponents);
  const inputRef = useRef<HTMLDivElement>(null!);
  const refContainer = useRef<HTMLDivElement>(null!);
  const filePickerRef = useRef<HTMLInputElement>(null!);

  const isComponentDisabled = useCallback((component: ComposerComponent): boolean => {
    return disabledComponents.includes(component);
  }, [disabledComponents]);

  const LoaderSpinner = () => {

    // console.log("loading...")
    return (
      <div>
      <Loader
        type="TailSpin"
        color={`#${themeColor}`}
        height={15}
        width={15}
        // timeout={10000} //3 secs
      /></div>
    );
  }

  //handle attachment button
  const [openFileSelector, { filesContent, plainFiles, clear, errors }] = useFilePicker({
    readAs: "DataURL",
    // //accept: "image/*",
    // multiple: true,
    // limitFilesConfig: { max: 1 },
    // // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
    filePickerRef: filePickerRef
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 10,
    //   minWidth: 10,
    // },
  });

  // if(errors.length){
  //   console.log(errors);
  // }

  

  // @ts-ignore

  if (disabledInput !== spanDisabledInput && !filesContent.length){
    //console.log("default span height: ", defaultSpanHeight);
    //console.log("span height: ", spanHeight);
    // console.log("setting spandisabledinput", disabledInput);
    setSpanDisabledInput(disabledInput);
  }

  useImperativeHandle(ref, () => {
      return {
        onSelectEmoji: handlerOnSelectEmoji,
      };
    });

  useEffect(() => {
    if (showChat && autofocus) inputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat]);

  useEffect(() => {
    setFirefox(isFirefox());
  }, []);

  useEffect(()=>{
    if(isShowingForm && inputRef.current !== null)
      inputRef.current.innerHTML='';
  }, [isShowingForm])

  useEffect(() => {

    // console.log("file content: ", filesContent);

    if (!!filesContent.length) {
      setSpanHeight(attachmentSpanHeight);
      refContainer.current.style.marginTop = `-${100-50}px`
      //setSpanOverFlow("hidden");
      setSpanDisabledInput(true);
      //console.log("updating span", spanDisabledInput);
    } else {

      // refContainer.current.style.marginTop = "0px"
      // setSpanHeight(`${inputRef.current.scrollHeight>75?75:inputRef.current.scrollHeight}px`);

    }

  }, [filesContent.length]);

  useEffect(() =>{

    let errorMessage = "Attachment failed!";

    if(errors.length && isAttaching){

      if(errors[0].fileSizeToolarge)
        errorMessage = 'File size is too large!';
      else if(errors[0].readerError)
        errorMessage = 'Problem occured while reading file!';

      toast.error(errorMessage, {position: "top-center"});
      setIsAttaching(false);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.length]);

  const resetInputHeight = () => {

    const { current } = inputRef
    setSpanHeight(`${current.scrollHeight>75?75:current.scrollHeight}px`);
    
    // console.log("scrollHeight: ",current.scrollHeight);
    if(current.scrollHeight>25 && current.scrollHeight<=75 && current !== null)
      refContainer.current.style.marginTop = `-${current.scrollHeight-35}px`
    else if(current.scrollHeight<=25)
      refContainer.current.style.marginTop = `0px`
    else if(current.scrollHeight>75)
      refContainer.current.style.marginTop = `-${75-35}px`

  }

  const handlerOnChange = (event) => {
    const { current } = inputRef;
    //console.log(current.innerText);
    onTextInputChange && onTextInputChange(event);
    
    //Disable image pasting
    if (current?.innerHTML.includes("img")) {
      inputRef.current.innerHTML = ""; 
    } //else setSpanHeight(defaultSpanHeight);
    
    setSpanHeight(`${event.target.scrollHeight>75?75:event.target.scrollHeight}px`)
    
    if(event.target.scrollHeight>25 && event.target.scrollHeight<75 && refContainer.current.style.marginTop !== `0px`)
      refContainer.current.style.marginTop = `-${event.target.scrollHeight-35}px`
    else if(event.target.scrollHeight<=25)
      refContainer.current.style.marginTop = `0px`
      
    //console.log("scrollHeight: ", event.target.scrollHeight)
  };

  const handlerSendMessage = () => {
    
    const { current } = inputRef
    //console.log(current?.innerHTML.includes('img'))
    //const Component = DOMPurify.sanitize(current.innerHTML)
    if(spanDisabledInput){

      //sendCustomComponent(() => {return <img src={clip} className="rcw-attachment-icon" />})
      //console.log("sending component");
      //sendMessage('non-text object', ()=>{return <div><img src={clip} className="rcw-attachment-icon" /></div>})
      
      //sendMessage('non-text object', ()=>{return <div className="rcw-new-non-text-message" dangerouslySetInnerHTML={{__html: Component}}></div>})
      sendMessage('non-text object', ()=>{return <div>{filesContent.map((file) => { //console.log(file.content)
        return (<div>{file.content.includes("image")?<CustomZoom user="client" media_url={file.content}/>:<div><LoaderSpinner/><Icon style={{fontSize: "12px"}}><Description style={{ fontSize: 15}}/>{file.name}</Icon></div>}</div>)
      })}</div>}, plainFiles)
      
      // sendMessage('non-text object', ()=>{return <div> {filesContent.map((file) => (
      //   <img alt={file.name} src={file.content} className="rcw-new-non-text-message"/>))}</div>})
          
      //sendMessage('')
      //if(!!!filesContent.length)
        //current.innerHTML = ''

      //sendMessage('')
      if (!!!filesContent.length) current.innerHTML = "";

      clear();

      //setSpanOverFlow("auto");
      setSpanDisabledInput(false);
      resetInputHeight();

    } else if (current?.innerHTML) {
      sendMessage(current.innerText.trim());
      current.innerHTML = ''
      setSpanHeight(`${current.scrollHeight>75?75:current.scrollHeight}px`);
      refContainer.current.style.marginTop = `0px`
    }

    
    //refContainer.current.style.marginTop = `-40px`
    // else if(current.scrollHeight<=25 && current !== null)
    //   refContainer.current.style.marginTop = `0px`
    //clear()
    //filecontent()
  };

  const handlerOnKeyPress = (event) => {

    const el = inputRef.current;
    event.target.style.overflow = event.target.scrollHeight>75?'auto':'hidden'

    if (event.charCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handlerSendMessage();
      //clear()
    }

    if (event.charCode === 13 && event.shiftKey) {
      event.preventDefault();
      insertNodeAtCaret(el);
      setEnter(true);
      event.target.scrollTop = event.target.scrollHeight;
      
      if(numOfNewline<3)
      setNumOfNewline(numOfNewline+1)
      setSpanHeight(`${event.target.scrollHeight>75?75:event.target.scrollHeight}px`)
      if(event.target.scrollHeight<=75)
        refContainer.current.style.marginTop = `-${event.target.scrollHeight-35}px`
      //setSpanHeight(`${event.target.scrollHeight}px`)
      //console.log(`${event.target.scrollHeight}px`)
      //console.log(refContainer.current.style.marginTop)
    }

    if (event.keyCode === 8) {
      //console.log(`${event.target.scrollHeight}px`)
      clear();
    }
  };

  const handlerOnKeyUp = (event) => {
    //console.log(event.target.innerText.length)
    //console.log(`${event.target.scrollHeight}px`)
    //console.log(spanHeight)
    //console.log(spanHeight)
    event.target.style.overflow = event.target.scrollHeight>75?'auto':'hidden'

    if (event.key === "Backspace") {

      if(event.target.innerText.trim().length < 1){
        setSpanHeight(defaultSpanHeight);
        refContainer.current.style.marginTop = `0px`
        event.target.innerHTML = ""; 
      }
      // const text = event.target.innerText.trim()
      
      // if(event.target.scrollHeight>26 && event.target.scrollHeight<=74){
      //   if(numOfNewline>event.target.innerText.length-event.target.innerText.replace(/\n/g, '').length)
      //     setNumOfNewline(event.target.innerText.length-event.target.innerText.replace(/\n/g, '').length)
        
      //   if(text.split("\n").length == prevRowCount){
      //     setSpanHeight(`${2+text.split("\n").length*24}px`)
      //     //event.target.innerHTML = event.target.innerHTML.replace(/\n$/, "")
      //   }
      //   console.log("spanHeight: ", spanHeight);
      //   console.log("scrollHeight: ", `${event.target.scrollHeight}px`)
      //   console.log(`${text.split("\n").length} <- rows`)
        
      //   setPrevRowCount(text.split("\n").length)
      // }
    }
    //setSpanHeight(`${event.target.scrollHeight>74?74:event.target.scrollHeight}px`)
    // if((event.target.innerHTML.match(/\n/g)||'').length>=1 && event.target.scrollHeight<=74)
    //   setSpanHeight(`${2+(event.target.innerHTML.match(/\n/g)||'').length*24}px`)
    const el = inputRef.current;
    if (!el) return true;
    // Conditions need for firefox
    if (firefox && event.key === "Backspace") {
      if (el.innerHTML.length === 1 && enter) {
        el.innerHTML = "";
        setEnter(false);
      } else if (brRegex.test(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace(brRegex, "");
      }
    }
    checkSize();
  };

  const handlerOnKeyDown = (event) => {
    
    const el = inputRef.current;

    if (event.key === "Backspace" && el) {
      const caretPosition = getCaretIndex(inputRef.current);
      const character = el.innerHTML.charAt(caretPosition - 1);
      if (character === "\n") {
        event.preventDefault();
        event.stopPropagation();
        el.innerHTML =
          el.innerHTML.substring(0, caretPosition - 1) +
          el.innerHTML.substring(caretPosition);
        updateCaret(el, caretPosition, -1);
      }
    }
  };

  const checkSize = () => {
    const senderEl = refContainer.current;
    const el = inputRef.current;
    if (senderEl && height !== senderEl.clientHeight) {
      const { clientHeight } = senderEl;
      setHeight(clientHeight);
      //console.log("el client height: ", el.clientHeight)
      onChangeSize(el.clientHeight>25 ? el.clientHeight + 55 : 90);
    }
  };

  const handlerOnSelectEmoji = (emoji) => {
    const el = inputRef.current;
    const { start, end } = getSelection(el);
    if (el.innerHTML) {
      const firstPart = el.innerHTML.substring(0, start);
      const secondPart = el.innerHTML.substring(end);
      el.innerHTML = `${firstPart}${emoji.native}${secondPart}`;
    } else {
      el.innerHTML = emoji.native;
    }
    updateCaret(el, start, emoji.native.length);
  };

  const handlerPressEmoji = () => {
    onPressEmoji();
    checkSize();
  };

  const onInputChange = (event) => {
    // console.log("target value: ", event.target.value)
    event.target.value = ''
  }

  // if (loading) {
  // 	return <div>Loading...</div>;
  // }

  // filesContent.map((file,index)=>(
  //   sendMessage(file.name)
  // ))

  // console.log(filesContent.)
  // const filecontent = () => {

  //   if (filesContent.length != 0){
  //     filesContent.map((file, index) => (
  //       sendMessage(file.content)
  //     ))
  //     clear()
  //   }
  //   // document.getElementById('filecontent')?.innerHTML
  // }

  // const [selectedFile, setSelectedFile] = useState();
  // const [isFilePicked, setIsFilePicked] = useState(false);
  // const changeHandler = (event) => {
  // 	setSelectedFile(event.target.files[0]);
  // };
  // const handleSubmission = () => {
  // 	const formData = new FormData();

  // 	formData.append('File', selectedFile);

  // 	fetch(
  // 		'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
  // 		{
  // 			method: 'POST',
  // 			body: formData,
  // 		}
  // 	)
  // 		.then((response) => response.json())
  // 		.then((result) => {
  // 			console.log('Success:', result);
  // 		})
  // 		.catch((error) => {
  // 			console.error('Error:', error);
  // 		});
  // };

  return (
    <div>
      <ToastContainer position="top-center" style={{ width: '200px', marginLeft: 'calc(calc(100% - 200px) / 2)' }} />
      {!isShowingForm && (
        <div ref={refContainer} className="rcw-sender">
          <div
            className={cn('rcw-new-message', {
              'rcw-message-disable': spanDisabledInput,
            })}
            style={{ height: spanHeight, overflow: 'hidden' }}
          >
            {!!filesContent.length && (
              <div>
                <IconButton
                  size={'small'}
                  edge={false}
                  className="rcw-attachment-preview-cancel-button"
                  onClick={() => {
                    if (!!!filesContent.length) inputRef.current.innerHTML = '';
                    //setSpanOverFlow("auto");
                    // setSpanHeight(`${inputRef.current.scrollHeight>75?75:inputRef.current.scrollHeight}px`);
                    // setSpanDisabledInput(false);
                    resetInputHeight();
                    clear();
                  }}
                >
                  <Cancel />
                </IconButton>
                <div>
                  {filesContent.map((file) => (
                    // <button className="rcw-attachment-preview">

                    <div className="rcw-attachment-preview">
                      {/* <IconButton size={"small"} edge={false} className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}><Cancel/></IconButton> */}
                      {/* <Button className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}/> */}
                      {/* <button className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}/> */}
                      {file.content.includes('image') ? (
                        <img alt={file.name} src={file.content} className="rcw-attachment-preview-img" />
                      ) : (
                        <div>
                          <Description style={{ fontSize: 40 }} />
                          <div style={{ fontSize: '14px' }}>{file.name}</div>
                        </div>
                      )}
                    </div>
                    // </button>
                  ))}
                </div>
              </div>
            )}
            <span
              spellCheck
              className="rcw-input"
              style={{ fontSize: isMobile ? '16px' : '14px' }}
              role="textbox"
              contentEditable={!spanDisabledInput}
              ref={inputRef}
              placeholder={placeholder}
              onInput={handlerOnChange}
              onChange={handlerOnChange}
              onKeyPress={handlerOnKeyPress}
              onKeyUp={handlerOnKeyUp}
              onKeyDown={handlerOnKeyDown}
            >
              {/* {!!filesContent.length? <div>
          {filesContent.map((file) => (
          // <button className="rcw-attachment-preview">
          
          <div className="rcw-attachment-preview"> */}
              {/* <IconButton size={"small"} edge={false} className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}><Cancel/></IconButton> */}
              {/* <Button className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}/> */}
              {/* <button className="rcw-attachment-preview-cancel-button" onClick={() => {setSpanOverFlow("auto"); setSpanHeight(defaultSpanHeight); setSpanDisabledInput(false); clear();}}/> */}
              {/* {file.content.includes("image")?<img alt={file.name} src={file.content} className="rcw-attachment-preview-img"/>:<Icon style={{fontSize: "18px"}}><Description style={{ fontSize: 20}}/>{file.name}</Icon>}
          </div>
          // </button>
            ))}
          </div>:null
        } */}
            </span>
          </div>
          <div style={{ display: 'flex', alignSelf: 'flex-end', alignItems: 'center' }}>
            {!isComponentDisabled(ComposerComponent.Emoji) && (
              <button className="rcw-picker-btn" type="submit" onClick={handlerPressEmoji} disabled={spanDisabledInput}>
                <img src={emoji} className="rcw-picker-icon" alt="" />
              </button>
            )}
            {!isComponentDisabled(ComposerComponent.Attachment) && (
              <>
                <input type="file" style={{ display: 'none' }} ref={filePickerRef} onChange={onInputChange} />
                <button
                  type="submit"
                  className="rcw-attachment"
                  onClick={() => {
                    openFileSelector();
                    setIsAttaching(true);
                  }}
                  disabled={disabledInput}
                >
                  <img src={clip} className="rcw-attachment-icon" alt="" />
                </button>
              </>
            )}
            <button type="submit" className="rcw-send" onClick={handlerSendMessage} disabled={disabledInput}>
              <img src={send} className="rcw-send-icon" alt={buttonAlt} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef(Sender);
