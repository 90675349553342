import { ElementType } from 'react';

export enum InteractiveButtonType {
  Button = 'button',
  InternalMediaButton = 'internalMediaButton',
};

export type InteractiveButton = {
  type: InteractiveButtonType;
  label: string;
  value: string | number;
  rawButton: Record<string, any>;
}

export type QuickButton = {
  component: ElementType;
  label: string;
  value: string | number;
  interactiveButton: InteractiveButton;
};

export type InternalMediaButton = {
  component: ElementType;
  label: string;
  value: string | number;
  interactiveButton: InteractiveButton;
};

export type InternalMediaInteractiveButtonWrapper = {
  prefix?: string;
  buttons: InteractiveButton[];
}

export enum MessageRatingType {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  REVOKED = 'Revoked',
}

type BaseMessage = {
  type: string;
  component: ElementType;
  sender: string;
  showAvatar: boolean;
  timestamp: string;
  customTimeStamp?: string;
  unread: boolean;
  customId?: string;
  props?: any;
  quickButtons?: QuickButton[];
  internalMediaButton?: {
    prefix?: string;
    buttons: InternalMediaButton[];
  };
  rating?: {
    type: MessageRatingType,
    updatedAt: string,
    comment?: string,
    lastErrorMessage?: string,
  };
  metadata?: Record<string, any>;
};

export interface MessageTypes extends BaseMessage {
  text: string;
  metadata?: {
    faqUuid?: string
  };
}


export type OptionFieldType = {
  id: number, 
  label: string, 
  name: string, 
  options: string[], 
  required: boolean, 
  type: string
}

export interface Link extends BaseMessage {
  title: string;
  link: string;
  target: string;
};

export interface LinkParams {
  link: string;
  title: string;
  target?: string;
}

export interface CustomCompMessage extends BaseMessage {
  props: any;
}

export enum ComposerComponent {
  Emoji = 'emoji',
  Attachment = 'attachment',
}

export type ResponsesState = {

  headerTitle: string;
  headerLogo: string;
  widgetIcon: string;
  avatarImage: string;
  themeColor: string;
  textColor: string;
  password: string;
  stompUrl: string;
  stompUser: string;
  parentUrl: string;
  optionFields: OptionFieldType[];
  
}

export interface BehaviorState {
  showChat: boolean;
  showContactForm: boolean;
  disabledInput: boolean;
  messageLoader: boolean;
  isLoadingPreviousMessages: boolean;
};

export interface MessagesState {
  messages: (MessageTypes | Link | CustomCompMessage)[];
  badgeCount: number;
  disableComposerComponents: ComposerComponent[];
  typingIndicatorExpiries: Array<{ responseIdentifier: string, expiry: number, stage?: string, partialContent?: string}>;
  ratingEnabled: boolean;
  ratingLoading: boolean;
  additionalMessageRatingMap: Record<string, (MessageTypes | Link | CustomCompMessage)['rating']>
  ratingNegativeCommentPrompt: string;
}

export interface QuickButtonsState {
  quickButtons: QuickButton[];
}

export interface ImageState {
  src: string;
  alt?: string;
  width: number;
  height: number;
}

export interface FullscreenPreviewState extends ImageState {
  visible?: boolean;
};

export interface GlobalState {
  messages: MessagesState;
  behavior: BehaviorState;
  quickButtons: QuickButtonsState;
  preview: FullscreenPreviewState;
  responses: ResponsesState;
  
}

export interface Metadata {
    faqUuid?: string
}
