import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../../store/types";
import './style.scss';

import minus from '../../../../../../assets/minus.svg';

type Props = {
  title: string;
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  titleAvatar?: string;
  fullScreenMode: boolean;
}

function Header({ title, subtitle, toggleChat, showCloseButton, titleAvatar, fullScreenMode }: Props) {
  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);
  const renderHeader = (showCloseButton && !fullScreenMode)
    || !!titleAvatar
    || !!subtitle;
  return renderHeader
    ? (
      <div className="rcw-header">
        {showCloseButton && !fullScreenMode &&
          <button className="rcw-close-button" 
            onClick={()=> {
              if (window.parent && parentUrl) {
                window.parent.postMessage("isCloseButton", parentUrl); 
              }
              toggleChat();
            }}
          >
            <img src={minus} className="rcw-close" alt="close" />
          </button>
        }
        <h4 className="rcw-title">
          {titleAvatar && <img src={titleAvatar} className="avatar" alt="profile" />}
          {/* {title} */}
        </h4>
        <span>{subtitle}</span>
      </div>
    )
    : <></>;
}

export default Header;
