
export function createOptionField(optionField: { id: number, label: string, name: string, options: string[], required: boolean, type: string}) {
  return {
      id: optionField.id, 
      label: optionField.label, 
      name: optionField.name, 
      options: optionField.options, 
      required: optionField.required, 
      type: optionField.type, 
    };
  }