import './style.scss';
// import Linkify from "react-linkify";
import logo from '../../../../../../assets/LogoIcon.svg';

type Props = {
  fullScreenMode: boolean;
}

function Footer({fullScreenMode}:Props) {
  return (
    <div className={fullScreenMode?"company-footer-fs":"company-footer"}>
        <img src={logo} style={{ height: '16px', width: '16px', marginRight: '4px' }} alt=""/>
        by&nbsp;
        {/* <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
        <a style={{ textDecoration: 'none', color: 'grey', outline: 'none' }} target="blank" href={decoratedHref} key={key}> {decoratedText}</a>
      )}>imBee.io</Linkify> */}
        <a style={{ textDecoration: 'none', color: 'grey', outline: 'none' }} target="blank" href={"https://www.imbee.io"}>imBee</a>
    </div>
  );
}

export default Footer;
