import { createReducer } from '../../utils/createReducer';
import { OptionFieldType, ResponsesState } from '../types';
import { createOptionField } from '../../utils/forms';
import { 

    SET_HEADER_TITLE,
    SET_HEADER_LOGO,
    SET_WIDGET_ICON,
    SET_THEME_COLOR,
    SET_TEXT_COLOR,
    ResponsesActions, 
    SET_PASSWORD,
    SET_STOMP_URL,
    SET_PARENT_URL,
    SET_AVATAR_IMAGE,
    SET_OPTION_FIELDS,
    SET_STOMP_USER

} from '../actions/types';


const initialState = {

    headerTitle: "",
    headerLogo: "",
    widgetIcon: "",
    avatarImage: "",
    themeColor: "",
    textColor: "",
    password: "",
    stompUrl: "",
    stompUser: "",
    parentUrl: "",
    optionFields: []
}

const responsesReducer = {

    [SET_HEADER_TITLE]: (state: ResponsesState, {title}) => ({ ...state, headerTitle: title}),
    [SET_HEADER_LOGO]: (state: ResponsesState, {logo}) => ({ ...state, headerLogo: logo}),
    [SET_WIDGET_ICON]: (state: ResponsesState, {icon}) => ({ ...state, widgetIcon: icon}),
    [SET_AVATAR_IMAGE]: (state: ResponsesState, {image}) => ({ ...state, avatarImage: image}),
    [SET_THEME_COLOR]: (state: ResponsesState, {color}) => ({ ...state, themeColor: color}),
    [SET_TEXT_COLOR]: (state: ResponsesState, {color}) => ({ ...state, textColor: color}),
    [SET_PASSWORD]: (state: ResponsesState, {pw}) => ({ ...state, password: pw}),
    [SET_STOMP_URL]: (state: ResponsesState, {url}) => ({ ...state, stompUrl: url}),
    [SET_STOMP_USER]: (state: ResponsesState, {stompUser}) => ({ ...state, stompUser }),
    [SET_PARENT_URL]: (state: ResponsesState, {url}) => ({ ...state, parentUrl: url}),
    [SET_OPTION_FIELDS]: (state: ResponsesState, {optionFields}) => ({ ...state, optionFields: [...optionFields.map((optionField: OptionFieldType) => createOptionField(optionField))]})

}

const responser = (state = initialState, action: ResponsesActions) => createReducer(responsesReducer, state, action);

export default responser;