import { createReducer } from '../../utils/createReducer';
import { BehaviorState } from '../types';

import {
  BehaviorActions,
  TOGGLE_CHAT,
  TOGGLE_CONTACT_FORM,
  TOGGLE_INPUT_DISABLED,
  TOGGLE_MESSAGE_LOADER,
  TOGGLE_PREVIOUS_MESSAGE_LOADER,
} from '../actions/types';

const initialState = {
  showChat: false,
  showContactForm: true,
  disabledInput: false,
  messageLoader: false,
  isLoadingPreviousMessages: false
};

const behaviorReducer = {
  
  [TOGGLE_CHAT]: (state: BehaviorState) => ({ ...state, showChat: !state.showChat}),
  [TOGGLE_CONTACT_FORM]: (state: BehaviorState) => ({ ...state, showContactForm: !state.showContactForm}),
  [TOGGLE_INPUT_DISABLED]: (state: BehaviorState) => ({ ...state, disabledInput: !state.disabledInput }),
  [TOGGLE_MESSAGE_LOADER]: (state: BehaviorState) => ({ ...state, messageLoader: !state.messageLoader }),
  [TOGGLE_PREVIOUS_MESSAGE_LOADER]: (state: BehaviorState) => ({ ...state, isLoadingPreviousMessages: !state.isLoadingPreviousMessages })
  
};
const behaviorer = (state: BehaviorState = initialState, action: BehaviorActions) => createReducer(behaviorReducer, state, action);
export default behaviorer;
