import { createReducer } from '../../utils/createReducer';
import { createQuickButton } from '../../utils/messages';
import { SET_QUICK_BUTTONS, QuickButtonsActions } from '../actions/types';
import { InteractiveButton, QuickButtonsState } from '../types'

const initialState = {
  quickButtons: []
};

const quickButtonsReducer = {
  [SET_QUICK_BUTTONS]: (_: QuickButtonsState, { buttons }) =>
    ({ quickButtons: [...buttons.map((button: InteractiveButton) => createQuickButton(button))] })
}

const quickButtoner = (state = initialState, action: QuickButtonsActions) => createReducer(quickButtonsReducer, state, action);
export default quickButtoner;
