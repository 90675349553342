import { useRef, useState } from "react";
import { Picker } from "emoji-mart";
import { useSelector} from 'react-redux';
import { GlobalState, MessageRatingType } from '../../../../store/types';
import cn from "classnames";
import {isMobile} from 'react-device-detect';

import Header from "./components/Header";
import Messages from "./components/Messages";
import Sender from "./components/Sender";
import Footer from "./components/Footer";

//import QuickButtons from "./components/QuickButtons";

import { AnyFunction } from "../../../../utils/types";

import "./style.scss";
import MessageRatingCommentPopup from './components/MessageRatingCommentPopup';
import { putMessageRating } from 'src/store/dispatcher';
import Loader from 'react-loader-spinner';

interface ISenderRef {
  onSelectEmoji: (event: any) => void;
}

type Props = {
  title: string;
  subtitle: string;
  senderPlaceHolder: string;
  showCloseButton: boolean;
  disabledInput: boolean;
  autofocus: boolean;
  className: string;
  sendMessage: AnyFunction;
  sendCustomComponent: AnyFunction;
  toggleChat: AnyFunction;
  profileAvatar?: string;
  titleAvatar?: string;
  onInteractiveButtonClicked?: AnyFunction;
  onHandleConversationScroll?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  emojis?: boolean;
  fullScreenMode: boolean;
};

function Conversation({
  title,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  disabledInput,
  autofocus,
  className,
  sendMessage,
  sendCustomComponent,
  toggleChat,
  profileAvatar,
  titleAvatar,
  onInteractiveButtonClicked,
  onHandleConversationScroll,
  onTextInputChange,
  sendButtonAlt,
  showTimeStamp,
  emojis,
  fullScreenMode
}: Props) {

  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);
  const messageRatingNegativeCommentPrompt = useSelector((state: GlobalState) => state.messages.ratingNegativeCommentPrompt);
  const messageRatingLoading = useSelector((state: GlobalState) => state.messages.ratingLoading);

  const [pickerOffset, setOffset] = useState(0);
  const senderRef = useRef<ISenderRef>(null!);
  const [pickerStatus, setPicket] = useState(false);
  const [showRatingCommentPopup, setShowRatingCommentPopup] = useState(false);
  const [ratingCommentPopupData, setRatingCommentPopupData] = useState<{type: MessageRatingType, messageId: string, prompt: string} | null>(null);

  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);

  const onSelectEmoji = (emoji) => {
    senderRef.current?.onSelectEmoji(emoji);
  };

  const togglePicker = () => {
    setPicket((prevPickerStatus) => !prevPickerStatus);
  };

  const handlerSendMsn = (event, event2?, event3?) => {
    sendMessage(event, event2, event3);
    // console.log("event4: ", event4);
    if (pickerStatus) setPicket(false);
  };

  const promptMessageRatingComment = (messageId: string, type: MessageRatingType) => {
    setRatingCommentPopupData({
      type,
      messageId,
      prompt: messageRatingNegativeCommentPrompt,
    });
    setShowRatingCommentPopup(true);
  }

  const handleMessageRatingSubmit = (messageId: string, type: MessageRatingType, comment?: string) => {
    putMessageRating(messageId, type, comment);
  };

  const handleRating = (messageId: string, type: MessageRatingType) => {
    if(messageRatingNegativeCommentPrompt && type === MessageRatingType.NEGATIVE) {
      promptMessageRatingComment(messageId, type);
      return;
    }
    handleMessageRatingSubmit(messageId, type);
  }

  return (
    <div
      className={cn(
        fullScreenMode || isMobile ? 'rcw-conversation-container-fs' : 'rcw-conversation-container',
        className,
      )}
      aria-live="polite"
      onMouseLeave={(e) => {
        window.parent && parentUrl && window.parent.postMessage('isNotFocusing', parentUrl);
      }}
    >
      <Header
        title={title}
        subtitle={subtitle}
        toggleChat={toggleChat}
        showCloseButton={showCloseButton}
        titleAvatar={titleAvatar}
        fullScreenMode={fullScreenMode}
      />
      <Messages
        profileAvatar={profileAvatar}
        showTimeStamp={showTimeStamp}
        onInteractiveButtonClicked={onInteractiveButtonClicked}
        onHandleConversationScroll={onHandleConversationScroll}
        fullScreenMode={fullScreenMode || isMobile}
        handleRating={handleRating}
      />
      {/* <QuickButtons onInteractiveButtonClicked={onInteractiveButtonClicked} /> */}
      {emojis && pickerStatus && (
        <Picker
          style={{
            position: 'absolute',
            bottom: pickerOffset,
            left: '0',
            width: '100%',
          }}
          onSelect={onSelectEmoji}
          exclude={['flags']}
        />
      )}
      <Sender
        sendMessage={handlerSendMsn}
        sendCustomComponent={sendCustomComponent}
        ref={senderRef}
        placeholder={senderPlaceHolder}
        disabledInput={disabledInput}
        autofocus={autofocus}
        onTextInputChange={onTextInputChange}
        buttonAlt={sendButtonAlt}
        onPressEmoji={togglePicker}
        onChangeSize={setOffset}
      />
      <Footer fullScreenMode={fullScreenMode || isMobile} />
      {showRatingCommentPopup && ratingCommentPopupData && (
        <MessageRatingCommentPopup
          onSubmit={handleMessageRatingSubmit}
          onClose={() => setShowRatingCommentPopup(false)}
          {...ratingCommentPopupData}
        />
      )}
      {messageRatingLoading && (
        <div className={`message-rating-loading-overlay`}>
          <Loader
            type="ThreeDots"
            color={`#${themeColor}`}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
}

export default Conversation;
