import format from 'date-fns/format';
//import markdownIt from 'markdown-it';
//import markdownItSup from 'markdown-it-sup';
//import markdownItSanitizer from 'markdown-it-sanitizer';
//import markdownItClass from '@toycode/markdown-it-class';
//import markdownItLinkAttributes from 'markdown-it-link-attributes';
//import linkifyIt from 'linkify-it';
import { ElementType, useCallback, useMemo } from 'react';
import { InternalMediaButton, MessageRatingType, MessageTypes } from 'src/store/types';
import { GlobalState } from 'src/store/types';
import { useSelector } from 'react-redux';
import Linkify from 'react-linkify';
import './styles.scss';
import { AnyFunction } from 'src/utils/types';
import thumbsUp from '../../../../../../../../assets/thumbs-up.svg';
import thumbsUpFull from '../../../../../../../../assets/thumbs-up-full.svg';
import thumbsDown from '../../../../../../../../assets/thumbs-down.svg';
import thumbsDownFull from '../../../../../../../../assets/thumbs-down-full.svg';

type Props = {
  message: MessageTypes;
  customComponent?: ElementType;
  showTimeStamp: boolean;
  onInteractiveButtonClicked?: AnyFunction;
  handleRating: (messageId: string, type: MessageRatingType) => void;
};

function Message({ message, showTimeStamp, onInteractiveButtonClicked, handleRating }: Props) {
  const CustomComponent = message.props;
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const textColor = useSelector((state: GlobalState) => state.responses.textColor);
  const ratingEnabled = useSelector((state: GlobalState) => state.messages.ratingEnabled);
  const backgroundColor = message.sender === 'client' ? `#${themeColor}` : '';
  const color = message.sender === 'client' ? textColor : '';
  const additionalRating = useSelector(
    (state: GlobalState) => state.messages.additionalMessageRatingMap[message.customId ?? ''],
  );

  const rating = useMemo(() => {
    return additionalRating ?? message.rating ?? null;
  }, [message, additionalRating]);

  const handlePositiveRatingClicked = useCallback(() => {
    if (!message.customId) return;
    if (rating && rating.type === MessageRatingType.POSITIVE) {
      handleRating(message.customId, MessageRatingType.REVOKED);
    } else {
      handleRating(message.customId, MessageRatingType.POSITIVE);
    }
  }, [handleRating, rating, message]);

  const handleNegativeRatingClicked = useCallback(() => {
    if (!message.customId) return;
    if (rating && rating.type === MessageRatingType.NEGATIVE) {
      handleRating(message.customId, MessageRatingType.REVOKED);
    } else {
      handleRating(message.customId, MessageRatingType.NEGATIVE);
    }
  }, [handleRating, rating, message]);

  const getInternalMediaButtonsToRender = (button: InternalMediaButton) => {
    const ComponentToRender = button.component;
    return <ComponentToRender onInteractiveButtonClicked={onInteractiveButtonClicked} button={button} />;
  };
  return (
    <div className={`rcw-${message.sender}`}>
      {message.props ? (
        <CustomComponent />
      ) : (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <div className="rcw-message-text" style={{ backgroundColor, color }}>
            {message.text}
            {message.internalMediaButton && (
              <div className="internal-media-buttons">
                {message.internalMediaButton.prefix && (
                  <div className="internal-media-buttons__prefix">{message.internalMediaButton.prefix}</div>
                )}
                {message.internalMediaButton.buttons.map((internalMediaButton) => (
                  <div
                    className="internal-media-buttons__button"
                    key={`internalMediaButton-${message.customId ?? ''}-${internalMediaButton.label}`}
                  >
                    {getInternalMediaButtonsToRender(internalMediaButton)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Linkify>
      )}
      <div className="features-container">
        {ratingEnabled && message.customId && message.sender !== 'client' && (
          <div className="rating-container">
            <button className="rating-button" onClick={handlePositiveRatingClicked}>
              <img
                src={rating?.type === MessageRatingType.POSITIVE ? thumbsUpFull : thumbsUp}
                className="rating-button-icon"
                alt="Thumb Up"
              />
            </button>
            <button className="rating-button" onClick={handleNegativeRatingClicked}>
              <img
                src={rating?.type === MessageRatingType.NEGATIVE ? thumbsDownFull : thumbsDown}
                className="rating-button-icon"
                alt="Thumb Down"
              />
            </button>
          </div>
        )}
        {showTimeStamp && (
          <>
            <div className="rcw-timestamp">
              {message.customTimeStamp ? message.customTimeStamp : format(new Date(message.timestamp), 'HH:mm')}
              {ratingEnabled && rating?.lastErrorMessage && (
                <span className="rating-submission-error">{rating?.lastErrorMessage}</span>
              )}
            </div>
          </>
        )}
        {message.metadata?.faqUuid && (
          <div className="cache-container">
            <p className="cache">Cached answer</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Message;
