import { InteractiveButton, MessageRatingType, MessagesState } from '../types';

import { MESSAGE_SENDER } from '../../constants';
import { createReducer } from '../../utils/createReducer';
import {
  createComponentMessage,
  createInternalMediaButton,
  createLinkSnippet,
  createNewMessage,
  createQuickButton,
} from '../../utils/messages';
import {
  ADD_COMPONENT_MESSAGE,
  ADD_NEW_LINK_SNIPPET,
  ADD_NEW_RESPONSE_MESSAGE,
  ADD_NEW_USER_MESSAGE,
  ADD_PREVIOUS_COMPONENT_MESSAGE,
  ADD_PREVIOUS_RESPONSE_MESSAGE,
  ADD_PREVIOUS_USER_MESSAGE,
  ADD_TYPING_INDICATOR_EXPIRY,
  DELETE_MESSAGES,
  DROP_MESSAGES,
  DROP_MESSAGE_BUTTONS,
  HIDE_AVATAR,
  MARK_ALL_READ,
  MessagesActions,
  PUT_MESSAGE_RATING_FAILED,
  PUT_MESSAGE_RATING_STARTED,
  PUT_MESSAGE_RATING_SUCCEEDED,
  REMOVE_TYPING_INDICATOR,
  SET_BADGE_COUNT,
  SET_DISABLE_COMPOSER_COMPONENTS,
  SET_MESSAGE_RATING_ENABLED,
  SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT,
} from '../actions/types';

const initialState = {
  messages: [],
  badgeCount: 0,
  disableComposerComponents: [],
  typingIndicatorExpiries: [],
  ratingEnabled: false,
  ratingLoading: false,
  additionalMessageRatingMap: {},
  ratingNegativeCommentPrompt: '',
};

const messagesReducer = {
  [ADD_NEW_USER_MESSAGE]: (state: MessagesState, { text, props, customTimeStamp, id, index }) => ({
    ...state,
    messages: index
      ? [
          ...state.messages.slice(0, index),
          createNewMessage(text, MESSAGE_SENDER.CLIENT, props, customTimeStamp, id),
          ...state.messages.slice(index),
        ]
      : [...state.messages, createNewMessage(text, MESSAGE_SENDER.CLIENT, props, customTimeStamp, id)],
  }),

  [ADD_NEW_RESPONSE_MESSAGE]: (
    state: MessagesState,
    { text, props, customTimeStamp, id, index, quickButtons, internalMediaButton },
  ) => {
    const newMessage = createNewMessage(
      text,
      MESSAGE_SENDER.RESPONSE,
      props,
      customTimeStamp,
      id,
      quickButtons?.map((button: InteractiveButton) => createQuickButton(button)),
      internalMediaButton
        ? {
            ...internalMediaButton,
            buttons: internalMediaButton.buttons.map((button: InteractiveButton) => createInternalMediaButton(button)),
          }
        : undefined,
    );
    return {
      ...state,
      messages: index
        ? [...state.messages.slice(0, index), newMessage, ...state.messages.slice(index)]
        : [...state.messages, newMessage],
      badgeCount: state.badgeCount + 1,
    };
  },

  [ADD_PREVIOUS_USER_MESSAGE]: (state: MessagesState, { text, props, customTimeStamp, id, index }) => ({
    ...state,
    messages: [createNewMessage(text, MESSAGE_SENDER.CLIENT, props, customTimeStamp, id), ...state.messages],
  }),

  [ADD_PREVIOUS_RESPONSE_MESSAGE]: (
    state: MessagesState,
    { text, props, customTimeStamp, id, index, quickButtons, internalMediaButton, rating, metadata },
  ) => ({
    ...state,
    messages: [
      createNewMessage(
        text,
        MESSAGE_SENDER.RESPONSE,
        props,
        customTimeStamp,
        id,
        quickButtons?.map((button: InteractiveButton) => createQuickButton(button)),
        internalMediaButton
          ? {
              ...internalMediaButton,
              buttons: internalMediaButton.buttons.map((button: InteractiveButton) =>
                createInternalMediaButton(button),
              ),
            }
          : undefined,
        rating,
        metadata,
      ),
      ...state.messages,
    ],
  }),

  [ADD_NEW_LINK_SNIPPET]: (state: MessagesState, { link, id }) => ({
    ...state,
    messages: [...state.messages, createLinkSnippet(link, id)],
  }),

  [ADD_COMPONENT_MESSAGE]: (state: MessagesState, { component, props, showAvatar, id }) => ({
    ...state,
    messages: [...state.messages, createComponentMessage(component, props, showAvatar, id)],
  }),

  [ADD_PREVIOUS_COMPONENT_MESSAGE]: (state: MessagesState, { component, props, showAvatar, id }) => ({
    ...state,
    messages: [createComponentMessage(component, props, showAvatar, id), ...state.messages],
  }),

  [DROP_MESSAGES]: (state: MessagesState, { position }) =>
    position === -1
      ? { ...state, messages: [...state.messages.slice(1)] }
      : position === 1
      ? { ...state, messages: [...state.messages.slice(0, -1)] }
      : { ...state, messages: [] },

  [DROP_MESSAGE_BUTTONS]: (state: MessagesState, { id }) => ({
    ...state,
    messages: state.messages.map((message) => (message.customId === id ? { ...message, quickButtons: [] } : message)),
  }),

  [HIDE_AVATAR]: (state: MessagesState, { index }) => (state.messages[index].showAvatar = false),

  [DELETE_MESSAGES]: (state: MessagesState, { count, id }) => ({
    ...state,
    messages: id
      ? state.messages.filter((_, index) => {
          const targetMsg = state.messages.findIndex((tMsg) => tMsg.customId === id);
          return index < targetMsg - count + 1 || index > targetMsg;
        })
      : // : state.messages.slice(0, state.messages.length - count)
        // : [...state.messages.splice(count, 0, createNewMessage("Replacement", MESSAGE_SENDER.CLIENT))]
        [...state.messages.slice(0, count), ...state.messages.slice(count + 1)],
  }),

  [SET_BADGE_COUNT]: (state: MessagesState, { count }) => ({ ...state, badgeCount: count }),

  [MARK_ALL_READ]: (state: MessagesState) => ({
    ...state,
    messages: state.messages.map((message) => ({ ...message, unread: false })),
    badgeCount: 0,
  }),

  [SET_DISABLE_COMPOSER_COMPONENTS]: (state: MessagesState, { components }) => ({
    ...state,
    disableComposerComponents: components,
  }),

  [ADD_TYPING_INDICATOR_EXPIRY]: (state: MessagesState, { responseIdentifier, expiry, stage, partialContent }) => {
    return {
      ...state,
      typingIndicatorExpiries: [
        ...state.typingIndicatorExpiries.filter((exp) => exp.responseIdentifier !== responseIdentifier),
        {
          responseIdentifier,
          expiry,
          stage,
          partialContent,
        },
      ].filter((exp) => exp.expiry > Date.now()),
    };
  },

  [REMOVE_TYPING_INDICATOR]: (state: MessagesState, { responseIdentifier }) => ({
    ...state,
    typingIndicatorExpiries: state.typingIndicatorExpiries.filter(
      (exp) => exp.responseIdentifier !== responseIdentifier && exp.expiry > Date.now(),
    ),
  }),

  [SET_MESSAGE_RATING_ENABLED]: (state: MessagesState, { enabled }) => ({
    ...state,
    ratingEnabled: enabled,
  }),

  [PUT_MESSAGE_RATING_STARTED]: (state: MessagesState, { messageId, rating }) => ({
    ...state,
    ratingLoading: true,
  }),

  [PUT_MESSAGE_RATING_SUCCEEDED]: (state: MessagesState, { messageId, rating }) => ({
    ...state,
    ratingLoading: false,
    additionalMessageRatingMap: {
      ...state.additionalMessageRatingMap,
      [messageId]: {
        ...(state.additionalMessageRatingMap[messageId] ?? {}),
        ...rating,
        lastErrorMessage: undefined,
      },
    },
  }),

  [PUT_MESSAGE_RATING_FAILED]: (state: MessagesState, { messageId, errorMessage }) => ({
    ...state,
    ratingLoading: false,
    additionalMessageRatingMap: {
      ...state.additionalMessageRatingMap,
      [messageId]: {
        ...(state.additionalMessageRatingMap[messageId] ?? {
          type: MessageRatingType.REVOKED,
        }),
        lastErrorMessage: errorMessage,
      },
    },
  }),

  [SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT]: (state: MessagesState, { prompt }) => ({
    ...state,
    ratingNegativeCommentPrompt: prompt,
  }),
};

const messages = (state = initialState, action: MessagesActions) => createReducer(messagesReducer, state, action);
export default messages;
