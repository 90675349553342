import { ElementType } from 'react';

import store from '.';
import * as actions from './actions';
import { LinkParams, ImageState, InteractiveButton, InternalMediaInteractiveButtonWrapper, ComposerComponent, MessageRatingType, MessageTypes, Metadata } from './types';

export function addUserMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number) {
  store.dispatch(actions.addUserMessage(text, props, customTimeStamp, id, index));
}

export function addResponseMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number, quickButtons?: InteractiveButton[], internalMediaButton?: InternalMediaInteractiveButtonWrapper, metadata?: Metadata ) {
  store.dispatch(actions.addResponseMessage(text, props, customTimeStamp, id, index, quickButtons, internalMediaButton, metadata));
}

export function addPreviousUserMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number) {
  store.dispatch(actions.addPreviousUserMessage(text, props, customTimeStamp, id, index));
}

export function addPreviousResponseMessage(text: string, props?: ElementType, customTimeStamp?: string, id?: string, index?: number, quickButtons?: InteractiveButton[], internalMediaButton?: InternalMediaInteractiveButtonWrapper, rating?: MessageTypes['rating'], metadata?: Metadata) {
  store.dispatch(actions.addPreviousResponseMessage(text, props, customTimeStamp, id, index, quickButtons, internalMediaButton, rating, metadata));
}

export function addLinkSnippet(link: LinkParams, id?: string) {
  store.dispatch(actions.addLinkSnippet(link, id));
}

export function toggleMsgLoader() {
  store.dispatch(actions.toggleMsgLoader());
}

export function togglePreviousMsgLoader() {
  store.dispatch(actions.togglePreviousMsgLoader());
}

export function renderCustomComponent(component: ElementType, props?: any, showAvatar = false, id?: string) {
  store.dispatch(actions.renderCustomComponent(component, props, showAvatar, id));
}

export function renderPreviousCustomComponent(component: ElementType, props?: any, showAvatar = false, id?: string) {
  store.dispatch(actions.renderPreviousCustomComponent(component, props, showAvatar, id));
}

export function toggleWidget() {
  store.dispatch(actions.toggleChat());
}

export function toggleContactForm(){
  store.dispatch(actions.toggleContactForm());
}

export function toggleInputDisabled() {
  store.dispatch(actions.toggleInputDisabled());
}

export function dropMessages(position?: number) {
  store.dispatch(actions.dropMessages(position));
}

export function dropMessageButtons(id: string) {
  store.dispatch(actions.dropMessageButtons(id));
}

export function isWidgetOpened(): boolean {
  return store.getState().behavior.showChat;
}

export function setQuickButtons(buttons: Array<{ label: string, value: string | number }>) {
  store.dispatch(actions.setQuickButtons(buttons));
}

export function deleteMessages(count: number, id?: string) {
  store.dispatch(actions.deleteMessages(count, id));
}

export function markAllAsRead() {
  store.dispatch(actions.markAllMessagesRead());
}

export function setBadgeCount(count: number) {
  store.dispatch(actions.setBadgeCount(count));
}

export function openFullscreenPreview(payload: ImageState) {
  store.dispatch(actions.openFullscreenPreview(payload));
}

export function closeFullscreenPreview() {
  store.dispatch(actions.closeFullscreenPreview());
}

export function setHeaderTitle(title: string) {
  store.dispatch(actions.setHeaderTitle(title));
}

export function setHeaderLogo(logo: string) {
  store.dispatch(actions.setHeaderLogo(logo));
}

export function setWidgetIcon(icon: string) {
  store.dispatch(actions.setWidgetIcon(icon));
}

export function setAvatarImage(imageUrl: string) {
  store.dispatch(actions.setAvatarImage(imageUrl));
}

export function setThemeColor(color: string){
  store.dispatch(actions.setThemeColor(color));
}

export function setTextColor(color: string){
  store.dispatch(actions.setTextColor(color));
}

export function setPassword(pw: string){
  store.dispatch(actions.setPassword(pw));
}

export function setStompUrl(url: string){
  store.dispatch(actions.setStompUrl(url));
}

export function setStompUser(stompUser: string) {
  store.dispatch(actions.setStompUser(stompUser))
}

export function setParentUrl(url: string){
  store.dispatch(actions.setParentUrl(url));
}

export function setOptionFields(optionFields: Array<{ id: number, label: string, name: string, options: string[], required: boolean, type: string}>){
  store.dispatch(actions.setOptionFields(optionFields))
}

export function setDisableComposerComponents(components: ComposerComponent[]) {
  store.dispatch(actions.setDisableComposerComponents(components));
}

export function addTypingIndicatorExpiry(responseIdentifier: string, expiry: number, stage?: string, partialContent?: string) {
  store.dispatch(actions.addTypingIndicatorExpiry(responseIdentifier, expiry, stage, partialContent));
}

export function removeTypingIndicator(responseIdentifier: string) {
  store.dispatch(actions.removeTypingIndicator(responseIdentifier));
}

export function setMessageRatingEnabled(enabled: boolean) {
  store.dispatch(actions.setMessageRatingEnabled(enabled));
}

export function putMessageRating(messageId: string, type: MessageRatingType, comment?: string) {
  store.dispatch(actions.putMessageRatingStarted(messageId, {
    type,
    comment,
  }));
}

export function setMessageRatingNegativeCommentPrompt(prompt: string) {
  store.dispatch(actions.setMessageRatingNegativeCommentPrompt(prompt));
}
