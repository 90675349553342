import FadeIn from "react-fade-in";
import './styles.scss';
import { InteractiveButton, QuickButton as QuickButtonType } from '@types';

export type Props = {
  messageID: string;
  button: QuickButtonType;
  onInteractiveButtonClicked: (event: any, button: InteractiveButton, messageID: string | undefined) => any;
};

function QuickButton({ messageID, button, onInteractiveButtonClicked }: Props) {
  return (
    <FadeIn>
      <button className="quick-button" onClick={(event) => onInteractiveButtonClicked(event, button.interactiveButton, messageID)}>
        {button.label}
      </button>
    </FadeIn>
  );
}

export default QuickButton;
