import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import Badge from "./components/Badge";
import { GlobalState } from "../../../../store/types";
import { setBadgeCount } from "../../../../store/actions";
import closeBlack from "../../../../assets/black-close-button.svg";
import closeWhite from "../../../../assets/white-close-button.svg";
import openLauncherBlack from "../../../../assets/support_black.svg";
import openLauncherWhite from "../../../../assets/support_white.svg";
import "./style.scss";

type WidgetConfig = {
  width: number;
  height: number;
}

type Props = {
  toggle: () => void;
  chatId: string;
  openLabel: string;
  closeLabel: string;
  closeImg: string;
  openImg: string;
  widgetConfig: WidgetConfig;
};

function Launcher({
  toggle,
  chatId,
  openImg,
  closeImg,
  openLabel,
  closeLabel,
  widgetConfig,
}: Props) {
  console.log("openImg: ", openImg??"");
  const dispatch = useDispatch();
  var fi = document.getElementById("favicon");
  const { showChat, badgeCount } = useSelector((state: GlobalState) => ({
    showChat: state.behavior.showChat,
    badgeCount: state.messages.badgeCount,
  }));
  const themeColor = useSelector((state: GlobalState) => state.responses.themeColor);
  const textColor = useSelector((state: GlobalState) => state.responses.textColor);
  const parentUrl = useSelector((state: GlobalState) => state.responses.parentUrl);
  const widgetWidth = widgetConfig.width?widgetConfig.width:60;
  const widgetHeight = widgetConfig.height?widgetConfig.height:60;
  const widgetLogoWidth = widgetConfig.width?widgetConfig.width:40;
  const widgetLogoHeight = widgetConfig.height?widgetConfig.height:40;
  const toggleChat = () => {
    
    if (!showChat) {
      dispatch(setBadgeCount(0));
      fi?.setAttribute("href", "favicon.ico");
    } else {

      // console.log("posting message")
    }

    toggle();
    //else fi?.setAttribute("href", "favicon.ico");
  };

  return (
    <div style={{alignSelf: 'flex-end'}}
      onMouseLeave = {(e) => {window.parent && parentUrl && window.parent.postMessage("isNotFocusing", parentUrl);}}
    >
    <button
      type="button"
      className={cn("rcw-launcher", { "rcw-hide-sm": showChat })}
      onClick={toggleChat}
      aria-controls={chatId}
      style={{backgroundColor: `#${themeColor}`, width: `${widgetWidth}px`, height: `${widgetHeight}px`}}
    >
      {!showChat && <Badge badge={badgeCount} />}
      {showChat ? (
        <img
          src={closeImg??(textColor==='black'?closeBlack:closeWhite)}
          className="rcw-close-launcher"
          style={{width: `${widgetLogoWidth-10}px`, height: `${widgetLogoHeight-10}px`}}
          alt={openLabel}
        />
      ) : (
        <img
          src={openImg??(textColor==='black'?openLauncherBlack:openLauncherWhite)}
          className="rcw-open-launcher"
          style={{width: `${widgetLogoWidth}px`, height: `${widgetLogoHeight}px`, marginTop: widgetConfig.width?"0px":"3px" }}
          alt={closeLabel}
        />
      )}
    </button></div>
  );
}

export default Launcher;
