import { MessageRatingType } from '../../../../../../store/types';
import './styles.scss';

import { useEffect, useMemo, useState } from 'react';
import close from '../../../../../../assets/close.svg';

const COMMENT_LIMIT = 1024;

type Props = {
  type: MessageRatingType;
  messageId: string;
  prompt: string;
  onSubmit: (messageId: string, type: MessageRatingType, comment: string) => void;
  onClose: () => void;
};

function MessageRatingCommentPopup({
  type,
  messageId,
  prompt,
  onSubmit,
  onClose
}: Props) {
    const [comment, setComment] = useState('');
    const [showing, setShowing] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        setTimeout(() => setShowing(true));
    }, []);

    useEffect(() => {
        if(!comment) {
            setSubmitDisabled(true);
        }else{
            setSubmitDisabled(false);
            if(comment.length > COMMENT_LIMIT) {
                setComment(comment.substring(0, COMMENT_LIMIT));
            }
        }
    }, [comment]);

    const commentCount = useMemo(() => comment.length, [comment]);

    const handleClose = () => {
        setShowing(false);
        setTimeout(() => onClose(), 300);
    }

    const handleSubmit = () => {
        if(submitDisabled) return;
        onSubmit(messageId, type, commentCount > COMMENT_LIMIT ? comment.substring(0, COMMENT_LIMIT) : comment);
        handleClose();
    }

    return (
      <>
        <div className={`message-rating-comment-popup-back ${showing ? 'showing' : ''}`}></div>
        <div className={`message-rating-comment-popup ${showing ? 'showing' : ''}`}>
          <div className="message-rating-comment-container">
            <div className="header">
              <div>Share Your Comment</div>
              <button className="close-button" onClick={handleClose}>
                <img src={close} alt="close" />
              </button>
            </div>
            <div className="prompt">{prompt}</div>
            <div className="comment">
                <textarea
                    className="comment-box"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Share Your Thoughts"
                ></textarea>
                <div className="comment-count">
                    <span>{commentCount}/{COMMENT_LIMIT}</span>
                </div>
            </div>
            <div className="action-bar">
              <button onClick={handleClose}>
                <span>Cancel</span>
              </button>
              <button className={`submit ${submitDisabled ? 'disabled' : ''}`} onClick={handleSubmit}>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default MessageRatingCommentPopup;
