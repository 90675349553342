import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import {isMobile} from 'react-device-detect';

//import * as GlobalState from '../../store/types';
import { GlobalState } from 'src/store/types';
import { AnyFunction } from '../../utils/types';
import { openFullscreenPreview } from '../../store/actions';

import Conversation from './components/Conversation';
import Launcher from './components/Launcher';
import FullScreenPreview from './components/FullScreenPreview';

import './style.scss';

type Props = {
  title: string;
  titleAvatar?: string;
  subtitle: string;
  onSendMessage: AnyFunction;
  onSendCustomComponent: AnyFunction;
  onToggleConversation: AnyFunction;
  senderPlaceHolder: string;
  onInteractiveButtonClicked: AnyFunction;
  onHandleConversationScroll?: AnyFunction;
  profileAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  chatId: string;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  launcherCloseImg: string;
  launcherOpenImg: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  widgetConfig: any;
  imagePreview?: boolean;
  zoomStep?: number;
  emojis?: boolean;
};

function WidgetLayout({
  title,
  titleAvatar,
  subtitle,
  onSendMessage,
  onSendCustomComponent,
  onToggleConversation,
  senderPlaceHolder,
  onInteractiveButtonClicked,
  onHandleConversationScroll,
  profileAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  onTextInputChange,
  chatId,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  widgetConfig,
  imagePreview,
  zoomStep,
  emojis,
}: Props) {
  const dispatch = useDispatch();

  // const { dissableInput, showChat } = useSelector((state: GlobalState.BehaviorState) => ({
  //   showChat: state.showChat,
  //   dissableInput: state.disabledInput,
  //   //visible: state.preview.visible,
  // }));

  // const visible = useSelector((state: GlobalState.FullscreenPreviewState) => ({

  //   visible: state.visible

  // }));

  const { dissableInput, showChat, visible } = useSelector((state: GlobalState) => ({
    showChat: state.behavior.showChat,
    dissableInput: state.behavior.disabledInput,
    visible: state.preview.visible,
  }));

  const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showChat) {
      messageRef.current = document.getElementById('messages') as HTMLDivElement;
    }
    return () => {
      messageRef.current = null;
    };
  }, [showChat]);

  const eventHandle = (evt) => {
    if (evt.target && evt.target.className === 'rcw-message-img') {
      const { src, alt, naturalWidth, naturalHeight } = evt.target as HTMLImageElement;
      const obj = {
        src: src,
        alt: alt,
        width: naturalWidth,
        height: naturalHeight,
      };
      dispatch(openFullscreenPreview(obj));
    }
  };

  /**
   * Previewer needs to prevent body scroll behavior when fullScreenMode is true
   */
  useEffect(() => {
    const target = messageRef?.current;
    if (imagePreview && showChat) {
      target?.addEventListener('click', eventHandle, false);
    }

    return () => {
      target?.removeEventListener('click', eventHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePreview, showChat]);

  useEffect(() => {
    document.body.setAttribute('style', `overflow: ${visible || fullScreenMode || isMobile ? 'hidden' : 'auto'}`);
  }, [fullScreenMode, visible]);

  return (
    <div
      className={cn(fullScreenMode || (isMobile && showChat) ? 'rcw-widget-container-fs' : 'rcw-widget-container', {
        'rcw-full-screen': fullScreenMode,
        'rcw-previewer': imagePreview,
        'rcw-close-widget-container ': !showChat,
        'mobile': isMobile,
      })}
    >
      {showChat && (
        <Conversation
          title={title}
          subtitle={subtitle}
          sendMessage={onSendMessage}
          sendCustomComponent={onSendCustomComponent}
          senderPlaceHolder={senderPlaceHolder}
          profileAvatar={profileAvatar}
          toggleChat={onToggleConversation}
          showCloseButton={showCloseButton}
          disabledInput={dissableInput}
          autofocus={autofocus}
          titleAvatar={titleAvatar}
          className={showChat ? 'active' : 'hidden'}
          onInteractiveButtonClicked={onInteractiveButtonClicked}
          onHandleConversationScroll={onHandleConversationScroll}
          onTextInputChange={onTextInputChange}
          sendButtonAlt={sendButtonAlt}
          showTimeStamp={showTimeStamp}
          emojis={emojis}
          fullScreenMode={fullScreenMode}
        />
      )}
      {customLauncher
        ? customLauncher(onToggleConversation)
        : !fullScreenMode &&
          !(isMobile && showChat) && (
            <Launcher
              toggle={onToggleConversation}
              chatId={chatId}
              openLabel={launcherOpenLabel}
              closeLabel={launcherCloseLabel}
              closeImg={launcherCloseImg}
              openImg={launcherOpenImg}
              widgetConfig={widgetConfig}
            />
          )}
      {imagePreview && <FullScreenPreview fullScreenMode={fullScreenMode} zoomStep={zoomStep} />}
    </div>
  );
}

export default WidgetLayout;
