import { ElementType } from 'react';

import { LinkParams, FullscreenPreviewState, InteractiveButton, InternalMediaInteractiveButtonWrapper, ComposerComponent, MessageRatingType, MessageTypes, Metadata } from '../types';

export const TOGGLE_CHAT = 'BEHAVIOR/TOGGLE_CHAT';
export const TOGGLE_CONTACT_FORM = 'BEHAVIOR/TOGGLE_CONTACT_FORM'
export const TOGGLE_INPUT_DISABLED = 'BEHAVIOR/TOGGLE_INPUT_DISABLED';
export const TOGGLE_MESSAGE_LOADER = 'BEHAVIOR/TOGGLE_MSG_LOADER';
export const TOGGLE_PREVIOUS_MESSAGE_LOADER = 'BEHAVIOR/TOGGLE_PREVIOUS_MSG_LOADER';
export const SET_BADGE_COUNT = 'BEHAVIOR/SET_BADGE_COUNT';
export const ADD_NEW_USER_MESSAGE = 'MESSAGES/ADD_NEW_USER_MESSAGE';
export const ADD_NEW_RESPONSE_MESSAGE = 'MESSAGES/ADD_NEW_RESPONSE_MESSAGE';
export const ADD_PREVIOUS_USER_MESSAGE = 'MESSAGES/ADD_PREVIOUS_USER_MESSAGE';
export const ADD_PREVIOUS_RESPONSE_MESSAGE = 'MESSAGES/ADD_PREVIOUS_RESPONSE_MESSAGE';
export const ADD_NEW_LINK_SNIPPET = 'MESSAGES/ADD_NEW_LINK_SNIPPET';
export const ADD_COMPONENT_MESSAGE = 'MESSAGES/ADD_COMPONENT_MESSAGE';
export const ADD_PREVIOUS_COMPONENT_MESSAGE = 'MESSAGES/ADD_PREVIOUS_COMPONENT_MESSAGE';
export const DROP_MESSAGES = 'MESSAGES/DROP_MESSAGES';
export const DROP_MESSAGE_BUTTONS = 'MESSAGES/DROP_MESSAGE_BUTTONS';
export const HIDE_AVATAR = 'MESSAGES/HIDE_AVATAR';
export const DELETE_MESSAGES = 'MESSAGES/DELETE_MESSAGES';
export const MARK_ALL_READ = 'MESSAGES/MARK_ALL_READ';
export const SET_DISABLE_COMPOSER_COMPONENTS = 'MESSAGES/SET_DISABLE_COMPOSER_COMPONENTS';
export const ADD_TYPING_INDICATOR_EXPIRY = 'MESSAGES/ADD_TYPING_INDICATOR_EXPIRY';
export const REMOVE_TYPING_INDICATOR = 'MESSAGES/REMOVE_TYPING_INDICATOR';
export const SET_MESSAGE_RATING_ENABLED = 'MESSAGES/SET_MESSAGE_RATING_ENABLED';
export const PUT_MESSAGE_RATING_STARTED = 'MESSAGES/PUT_MESSAGE_RATING_STARTED';
export const PUT_MESSAGE_RATING_SUCCEEDED = 'MESSAGES/PUT_MESSAGE_RATING_SUCCEEDED';
export const PUT_MESSAGE_RATING_FAILED = 'MESSAGES/PUT_MESSAGE_RATING_FAILED';
export const SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT = 'MESSAGES/SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT';
export const SET_QUICK_BUTTONS = 'SET_QUICK_BUTTONS';
export const OPEN_FULLSCREEN_PREVIEW = 'FULLSCREEN/OPEN_PREVIEW';
export const CLOSE_FULLSCREEN_PREVIEW = 'FULLSCREEN/CLOSE_PREVIEW';
export const SET_HEADER_TITLE = 'RESPONSES/SET_HEADER_TITLE';
export const SET_HEADER_LOGO = 'RESPONSES/SET_HEADER_LOGO';
export const SET_WIDGET_ICON = 'RESPONSES/SET_WIDGET_ICON';
export const SET_AVATAR_IMAGE = 'RESPONSES/SET_AVATAR_IMAGE';
export const SET_THEME_COLOR = 'RESPONSES/SET_THEME_COLOR';
export const SET_TEXT_COLOR = 'RESPONSES/SET_TEXT_COLOR';
export const SET_SESSION_ID = 'RESPONSES/SET_SESSION_ID';
export const SET_PASSWORD = 'RESPONSES/SET_PASSWORD';
export const SET_STOMP_URL = 'RESPONSES/SET_STOMP_URL';
export const SET_STOMP_USER = 'RESPONSES/SET_STOMP_USER';
export const SET_PARENT_URL = "RESPONSES/SET_PARENT_URL"
export const SET_OPTION_FIELDS = 'RESPONSES/SET_OPTION_FIELDS';


export interface ToggleChat {
  type: typeof TOGGLE_CHAT;
}

export interface ToggleContactForm {
  type: typeof TOGGLE_CONTACT_FORM;
}

export interface ToggleInputDisabled {
  type: typeof TOGGLE_INPUT_DISABLED;
}

export interface AddUserMessage {
  type: typeof ADD_NEW_USER_MESSAGE;
  text: string;
  props?: ElementType;
  customTimeStamp?: string;
  id?: string;
  index?: number;
}

export interface AddResponseMessage {
  type: typeof ADD_NEW_RESPONSE_MESSAGE;
  text: string;
  props?: ElementType;
  customTimeStamp?: string;
  id?: string;
  index?: number;
  quickButtons?: InteractiveButton[];
  internalMediaButton?: InternalMediaInteractiveButtonWrapper;
  metadata?: Metadata;
}

export interface AddPreviousUserMessage {
  type: typeof ADD_PREVIOUS_USER_MESSAGE;
  text: string;
  props?: ElementType;
  customTimeStamp?: string;
  id?: string;
  index?: number;
}

export interface AddPreviousResponseMessage {
  type: typeof ADD_PREVIOUS_RESPONSE_MESSAGE;
  text: string;
  props?: ElementType;
  customTimeStamp?: string;
  id?: string;
  index?: number;
  quickButtons?: InteractiveButton[];
  internalMediaButton?: InternalMediaInteractiveButtonWrapper;
  rating?: MessageTypes['rating'];
  metadata?: Metadata
} 

export interface ToggleMsgLoader {
  type: typeof TOGGLE_MESSAGE_LOADER;
}

export interface TogglePreviousMsgLoader {
  type: typeof TOGGLE_PREVIOUS_MESSAGE_LOADER;
}

export interface AddLinkSnippet {
  type: typeof ADD_NEW_LINK_SNIPPET;
  link: LinkParams;
  id?: string;
}

export interface RenderCustomComponent {
  type: typeof ADD_COMPONENT_MESSAGE;
  component: ElementType;
  props?: any;
  showAvatar?: boolean;
  id?: string;
}

export interface RenderPreviousCustomComponent {
  type: typeof ADD_PREVIOUS_COMPONENT_MESSAGE;
  component: ElementType;
  props?: any;
  showAvatar?: boolean;
  id?: string;
}

export interface DropMessages {
  type: typeof DROP_MESSAGES;
  position?: number;
}

export interface DropMessageButtons {
  type: typeof DROP_MESSAGE_BUTTONS;
  id: string;
}

export interface HideAvatar {
  type: typeof HIDE_AVATAR;
  index: number;
}

export interface DeleteMessages {
  type: typeof DELETE_MESSAGES;
  count: number;
  id?: string;
}

export interface SetQuickButtons {
  type: typeof SET_QUICK_BUTTONS;
  buttons: Array<{ label: string, value: string | number }>;
}

export interface SetBadgeCount {
  type: typeof SET_BADGE_COUNT;
  count: number;
}

export interface SetHeaderTitle{
  type: typeof SET_HEADER_TITLE
  title: string;
}

export interface SetHeaderLogo{
  type: typeof SET_HEADER_LOGO
  logo: string
}

export interface SetWidgetIcon{
  type: typeof SET_WIDGET_ICON
  icon: string
}

export interface SetAvatarImage{
  type: typeof SET_AVATAR_IMAGE
  image: string
}

export interface SetThemeColor{
  type: typeof SET_THEME_COLOR
  color: string
}

export interface SetTextColor{
  type: typeof SET_TEXT_COLOR
  color: string
}

export interface SetPassword{
  type: typeof SET_PASSWORD
  pw: string
}

export interface SetStompUrl{
  type: typeof SET_STOMP_URL
  url: string
}

export interface SetStompUser {
  type: typeof SET_STOMP_USER;
  stompUser: string;
}

export interface SetParentUrl{
  type: typeof SET_PARENT_URL
  url: string
}

export interface SetOptionFields{
  type: typeof SET_OPTION_FIELDS
  optionFields: Array<{ id: number, label: string, name: string, options: string[], required: boolean, type: string }>;
}

export interface MarkAllMessagesRead {
  type: typeof MARK_ALL_READ;
}

export interface SetDisableComposerComponents {
  type: typeof SET_DISABLE_COMPOSER_COMPONENTS;
  components: ComposerComponent[];
}

export interface AddTypingIndicatorExpiry {
  type: typeof ADD_TYPING_INDICATOR_EXPIRY;
  responseIdentifier: string;
  expiry: number;
  stage?: string;
  partialContent?: string;
}

export interface RemoveTypingIndicator {
  type: typeof REMOVE_TYPING_INDICATOR;
  responseIdentifier: string;
}

export interface SetMessageRatingEnabled {
  type: typeof SET_MESSAGE_RATING_ENABLED;
  enabled: boolean;
}

export interface PutMessageRatingStarted {
  type: typeof PUT_MESSAGE_RATING_STARTED;
  messageId: string;
  rating: {
    type: MessageRatingType;
    comment?: string;
  }
}

export interface PutMessageRatingSucceeded {
  type: typeof PUT_MESSAGE_RATING_SUCCEEDED;
  messageId: string;
  rating: {
    type: MessageRatingType;
    comment?: string;
  }
}

export interface PutMessageRatingFailed {
  type: typeof PUT_MESSAGE_RATING_FAILED;
  messageId: string;
  errorMessage: string;
}

export interface SetMessageRatingNegativeCommentPrompt {
  type: typeof SET_MESSAGE_RATING_NEGATIVE_COMMENT_PROMPT;
  prompt: string;
}

export type ResponsesActions = SetHeaderTitle | SetHeaderLogo | SetWidgetIcon | SetAvatarImage | SetThemeColor | SetTextColor | SetPassword | SetStompUrl | SetStompUser | SetParentUrl | SetOptionFields;

export type BehaviorActions = ToggleChat | ToggleInputDisabled | ToggleMsgLoader | ToggleContactForm | TogglePreviousMsgLoader;

export type MessagesActions = AddUserMessage | AddResponseMessage | AddPreviousUserMessage | AddPreviousResponseMessage | AddLinkSnippet | RenderCustomComponent | RenderPreviousCustomComponent
  | DropMessages | DropMessageButtons | HideAvatar | DeleteMessages | MarkAllMessagesRead | SetBadgeCount | SetDisableComposerComponents
  | AddTypingIndicatorExpiry | RemoveTypingIndicator
  | SetMessageRatingEnabled | PutMessageRatingStarted | PutMessageRatingSucceeded | PutMessageRatingFailed | SetMessageRatingNegativeCommentPrompt;

export type QuickButtonsActions = SetQuickButtons;

export interface openFullscreenPreview {
  type: typeof OPEN_FULLSCREEN_PREVIEW;
  payload: FullscreenPreviewState
}

export interface closeFullscreenPreview {
  type: typeof CLOSE_FULLSCREEN_PREVIEW;
}

export type FullscreenPreviewActions = openFullscreenPreview | closeFullscreenPreview;